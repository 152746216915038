import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import React, { useEffect, useMemo, useState } from 'react';
import { columnDateTime, columnUseYn } from '../../utils/dataTableUtil';

import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import CustomerDialog from '../../components/Customer/CustomerDialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import SearchMenu from '../../components/Menu/SearchMenu';
import ServiceMenu from '../../components/Menu/ServiceMenu';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import {
  shopListState,
  enterpriseList,
  associationsList,
  myInfoIds,
} from '../../recoil/atoms';

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  searchText: '',
  useYN: 'Y',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const customerService = ServiceProvider.customer;
const codeService = ServiceProvider.code;

const CustomerList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const myInfo = useRecoilValue(myInfoIds);
  const { myAssociationId, myEnterpriseId, myShopId } = myInfo;
  const shops = useRecoilValue(shopListState);
  const enterprises = useRecoilValue(enterpriseList);
  const associations = useRecoilValue(associationsList);
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    customerService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );

  const [currentCustomerId, setCurrentCustomerId] = useState(null);
  const [codes, setCodes] = useState({});

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return r.value === 'A_OFF' || _.startsWith(r.value, 'C_');
    });
    return !(_.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0);
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    setCodes((ps) => ({ ...ps, associations, enterprises, shops }));
  }, [shops, enterprises, associations]);

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        await getList({
          ...searchConditions,
          associations: [myAssociationId],
          enterprises: [myEnterpriseId],
          shops: [myShopId],
        });
      }
    })();
  }, [myInfoLoadable.state]);

  const [companyCodes, setCompanyCodes] = useState({});

  const getCustomerCompanyCodes = async () => {
    if (myInfo.myRoleCode === 'A_MST') {
      const { data } = await codeService.customerCompanies(myAssociationId);
      if (data) {
        const customerCompanies = [...data];
        setCompanyCodes({ customerCompanies });
      }
    } else {
      const { data } = await codeService.customerCompanies(
        myAssociationId,
        myEnterpriseId,
        myShopId
      );

      if (data) {
        const customerCompanies = [...data];
        setCompanyCodes({ customerCompanies });
      }
    }
  };
  useEffect(() => {
    if (myInfo.myAssociationId) getCustomerCompanyCodes();
  }, [myInfo]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() => {
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      );
                    }}
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'associations')
                              ),
                              associationId: value,
                            });
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(enterprises),
                            });
                          handleSelectAll(
                            value,
                            'associations',
                            {
                              enterprises,
                              shops,
                            },
                            {
                              enterprises: [],
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검법인</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'enterprises')
                              ),
                              enterpriseId: value,
                            });
                          handleSelectAll(
                            value,
                            'enterprises',
                            {
                              shops,
                            },
                            {
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'shops');
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">사용여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_all"
                            name="useYN"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === ''}
                          />
                          <label htmlFor="radio_useYN_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_y"
                            name="useYN"
                            value="Y"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.YES}
                          />
                          <label htmlFor="radio_useYN_y">사용</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_n"
                            name="useYN"
                            value="N"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.NO}
                          />
                          <label htmlFor="radio_useYN_n">미사용</label>
                        </div>
                      </div>
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">키워드</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="고객 이름, 휴대전화로 검색"
                        onChange={({ target: { value } }) => {
                          let v = value;
                          const numRegExp = /[0-9]/g;
                          if (numRegExp.test(Number(value[0]))) {
                            v = CommonUtil.Formatter.cellphone(value);
                          }

                          setSearchConditions((ps) => ({
                            ...ps,
                            searchText: v,
                          }));
                        }}
                      />
                    </div>
                  </SearchMenu>
                  <ServiceMenu
                    title={'고객'}
                    onClickRegisterBtn={() => setCurrentCustomerId(0)}
                    excelData={{
                      type: 'customer',
                      params: {
                        ...searchConditions,
                        page: lazyParams.page + 1,
                        size: lazyParams.rows,
                      },
                    }}
                    buttonVisible={checkRegistAuth(myInfoLoadable.contents)}
                  />

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      value={data}
                      lazy
                      loading={loading}
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      emptyMessage="데이터가 없습니다."
                    >
                      {/*<Column field="customerId" header="ID" />*/}
                      <Column
                        header="순번"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="customerName"
                        header="고객명"
                        body={({ customerId, customerName }) => (
                          <a
                            href="#"
                            onClick={() => setCurrentCustomerId(customerId)}
                          >
                            {customerName}
                          </a>
                        )}
                      />
                      <Column
                        field="contact"
                        header="연락처"
                        body={({ contact }) => (
                          <span>
                            {contact && CommonUtil.Formatter.phone(contact)}
                          </span>
                        )}
                      />
                      <Column
                        field="customerCompanyName"
                        header={
                          <div>
                            <span>소속매매상사명</span>
                            <br />
                            <span>매매상사대표전화</span>
                          </div>
                        }
                        body={({
                          customerCompanyName,
                          customerCompanyMainPhone,
                        }) => (
                          <div>
                            <span>{customerCompanyName}</span>
                            <br />
                            <span>
                              {customerCompanyMainPhone &&
                                CommonUtil.Formatter.phone(
                                  customerCompanyMainPhone
                                )}
                            </span>
                          </div>
                        )}
                      />
                      <Column
                        field="useYn"
                        header="사용여부"
                        body={({ useYn }) => columnUseYn(useYn)}
                        bodyClassName="text-center"
                      />
                      <Column
                        field="regTime"
                        header={
                          <div>
                            <span>등록사용자</span>
                            <br />
                            <span>등록일시</span>
                          </div>
                        }
                        body={({ regTime, regByUserName }) => (
                          <div>
                            <span>{regByUserName}</span>
                            <br />
                            <span>{columnDateTime(regTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="modTime"
                        header={
                          <div>
                            <span>수정사용자</span>
                            <br />
                            <span>수정일시</span>
                          </div>
                        }
                        body={({ modTime, modByUserName }) => (
                          <div>
                            <span>{modByUserName}</span>
                            <br />
                            <span>{columnDateTime(modTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="delTime"
                        header={
                          <div>
                            <span>삭제사용자</span>
                            <br />
                            <span>삭제일시</span>
                          </div>
                        }
                        body={({ delTime, delByUserName }) => (
                          <div>
                            <span>{columnDateTime(delTime)}</span>
                            <br />
                            <span>{delByUserName}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentCustomerId !== null && (
                    <CustomerDialog
                      visible
                      getList={getList}
                      params={[
                        searchConditions,
                        lazyParams.page,
                        lazyParams.rows,
                      ]}
                      companyCodes={companyCodes}
                      userInfo={myInfoLoadable.contents}
                      customerId={currentCustomerId}
                      onHide={() => {
                        setCurrentCustomerId(null);
                      }}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CustomerList, comparisonFn);
