import axios from 'axios';
import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

const BASE_URI = '/apis/customers/companies';
export const CustomerCompanyService = (function () {
  let instance;
  const baseService = BaseService.getInstance(BASE_URI, 'customerCompany');

  function init() {
    return {
      ...baseService,
      register: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI, data);
      },
      update: async function (data) {
        return await AxiosUtil.Async.put(BASE_URI, data);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getData: async function (customerCompanyId) {
        return await AxiosUtil.Async.get(BASE_URI + `/${customerCompanyId}`);
      },
      delete: async function (customerCompanyId) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${customerCompanyId}`);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();

export const CustomerCompanySafe = {
  getList: async (data) => {
    const res = await axios.get(`${BASE_URI}/safe6`, {
      params: data,
    });
    return res.data.data;
  },

  getData: async (id) => {
    const res = await axios.get(`${BASE_URI}/safe6/${id}`);
    return res.data;
  },

  register: async (data) => {
    const res = await axios.post(`${BASE_URI}/safe6`, data);
    return res.data;
  },

  update: async (data) => {
    const res = await axios.put(`${BASE_URI}/safe6`, data);
    return res.data;
  },

  deleteData: async (id) => {
    const res = await axios.delete(`${BASE_URI}/safe6/${id}`);
    return res.data;
  },
};

export default CustomerCompanySafe;
