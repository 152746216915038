import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Modal from '../../components/Atoms/Modal';
import * as Button from '../../components/Atoms/Button';
import Counseling from '../../components/Accident/Counseling';
import CustomerDocument from '../../components/Accident/CustomerDocument';
import PartnerDocument from '../../components/Accident/PartnerDocument';
import WarrantyDiagnosis from '../../components/Accident/WarrantyDiagnosis.jsx';
import Claim from '../../services/ClaimService';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  useRecoilValueLoadable,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { ServiceProvider } from '../../services/index';
import SendSMS from './SendSMS.jsx';
import SendPush from './SendPush.jsx';
import { Partner } from '../../services/PartnerService';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  CENTER_DOCS,
  DOC_TYPE,
  REPAIR_DOCS,
} from '../../constants/Constants.js';
import { shopListState } from '../../recoil/atoms.js';
import { Shop } from '../../services/CodeService.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
const kakaoService = ServiceProvider.kakao;
const partnerService = ServiceProvider.partner;

function ClaimDialog() {
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfo.contents.roleCode === 'D_ADM';
  const aid = myInfo.contents.associationInfo?.associationId;
  const history = useHistory();
  const oldData = history.location.pathname.includes('/old/');
  const today = new Date();
  const [showModal, setShowModal] = useState(false);
  const [showCarInfoModal, setShowCarInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pushLoading, setPushLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    carNbr: '',
    carVIN: '',
    newCarNbr: '',
    customerName: '',
    customerContact: '',
    association: { value: '' },
    warranty: { value: '' },
    receiptDate: null,
    addType: { value: '' },
    note: '', //  증상 입력
    vehicleValue: '', // 차량가액
  });
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [docs, setDocs] = useState([]); // claimDocs
  const [carDetailData, setCarDetailData] = useState(null);
  const [carAddress, setCarAddress] = useState('');
  const [warrantyData, setWarrantyData] = useState({
    buyDate: null,
    checkDistance: 0,
    distanceDriven: 0,
    diagnosisType: [],
    reservationDate: '',
    producingCountry: 'D',
    carName: '',
    partnerInfo: [
      {
        partnerName: '',
        partnerType: '', // 진단점 P_D, 수리점 P_R, 둘다 P_DR
        claimStatusCode: '',
        receiptDate: '',
        dueDate: '',
        paymentAmt: 0,
        carNbr: '',
        statementNumber: '',
        checkDate: '',
        partnerId: 0,
        repairCode: '',
        reservationDate: '',
        documentGroupId: '',
        useYn: '',
        fileData: [],
      },
    ],
    radius: 0,
    partnerNote: '', // opt
  });
  const [shopId, setShopId] = useState(0);
  const [funnelsType, setFunnelsType] = useState({
    value: 'WC',
    label: '전화 / 소비자',
  });
  const [carYX, setCarYX] = useState('');
  const [partnerList, setPartnerList] = useState([]);
  const [partnerAllList, setPartnerAllList] = useState([]);
  const [fileItems, setFileItems] = useState([]);
  const [deleteImgs, setDeleteImgs] = useState([]);
  const [partnerDeleteImgs, setPartnerDeleteImgs] = useState([]);
  const [copyFile, setCopyFile] = useState([]);
  const [partnerFile, setPartnerFile] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [statusCode, setStatusCode] = useState('Z01');
  const [toggleStates, setToggleStates] = useState([
    { idx: 3, status: 'Z03', value: false }, // 서류완료
    { idx: 4, status: 'Z04', value: false }, // 거절완료
    { idx: 5, status: 'Z05', value: false }, // 입고안내 완료
    { idx: 6, status: 'Z06', value: false }, // 입고점검 완료 - 진단점앱에서 사진 올라온 경우
    { idx: 9, status: 'Z17', value: false }, // 승인대기 - '서류반영' 클릭시
    { idx: 7, status: 'Z07', value: false }, // 수리중 - 승인견적서, 승인 보내기 완료 시
    { idx: 8, status: 'Z08', value: false }, // 수리완료 - 진단점앱에서 수리사진 올라온 경우
  ]);

  const [repairData, setRepairData] = useState({
    date: '',
    maintenanceCode: '',
    workYN: '',
    workFee: null, // int
    repairNote: '',
  }); // 수리정보

  const [insuranceInfo, setInsuranceInfo] = useState({
    payDate: '',
    paymentAmt: null, // int
    deductibleAmt: null, // int
    completeDate: '',
    insurancePolicyNumber: '', // 증권번호
    code: '10',
  });

  const [consultationList, setConsultationList] = useState([]);
  const [showSmsMoal, setShowSmsModal] = useState(false);
  const [showSmsType, setShowSmsType] = useState('');
  const [showPushModal, setShowPushModal] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState(null);
  const [showPushType, setShowPushType] = useState('');
  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '15332729',
    msgSubType: null,
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
    url: null, // opt
  });
  const [oriCarAddress, setOriCarAddress] = useState('');
  const [newStatusCode, setNewStatusCode] = useState(null);
  const { id } = useParams();
  const [carInfo, setCarInfo] = useState([]);
  const [userNames, setUserNames] = useState({
    firstId: '',
    firstName: '',
    lastId: '',
    lastName: '',
  });

  const handleToggleButton = (name, checked) => {
    if (!detailData?.dataId) return;
    setToggleStates((prev) =>
      prev.map((state) =>
        state.status === name ? { ...state, value: checked } : state
      )
    );

    if (name === 'Z17') {
      setStatusCode(name);
      setNewStatusCode(name);
      setToggleStates((prev) =>
        prev.map((state) =>
          state.status === 'Z06' ? { ...state, value: true } : state
        )
      );
      return;
    }

    if (!checked) {
      const idx = Number(name.slice(1, 3));
      if (idx === 4 || idx === 5) {
        setStatusCode(`Z03`);
        setNewStatusCode(`Z03`);
      } else if (idx === 6) {
        setStatusCode(`Z18`);
        setNewStatusCode(`Z18`);
      } else {
        setStatusCode(`Z0${idx - 1}`);
        setNewStatusCode(`Z0${idx - 1}`);
      }
    } else {
      setStatusCode(name);
      setNewStatusCode(name);
    }
  };

  useEffect(() => {
    if (!!newStatusCode) {
      registerClaim('수정');
    }
  }, [newStatusCode]);

  useEffect(() => {
    if (statusCode) {
      const index = Number(statusCode.slice(1, 3));
      setToggleStates((prevStates) =>
        prevStates.map((state) => {
          if (index === 18 && state.idx < 6) {
            return { ...state, value: true };
          }
          if (index === 17 && state.idx < 7) {
            return { ...state, value: true };
          }
          if (state.idx <= index && index !== 18 && index !== 17) {
            return { ...state, value: true };
          } else {
            return { ...state, value: false };
          }
        })
      );
    }
  }, [statusCode]);

  const getAddressInfo = async (address) => {
    try {
      const {
        documents: [res],
      } = await kakaoService.getAddressInfo({
        query: address,
      });
      if (res) {
        const coordinates = `${res.y},${res.x}`;
        setCarYX(coordinates);
        return coordinates;
      } else {
        setCarYX('');
        return null;
      }
    } catch (error) {
      console.error(error);
      setCarYX('');
      return null;
    }
  };
  const [partnerAllType, setPartnerAllType] = useState([]);
  const getPartnerAllType = async () => {
    try {
      const data = await Claim.getDocsType('R');
      setPartnerAllType(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!partner) {
      getPartnerAllType();
    }
  }, [partner]);

  const getPartnerList = async (location, radius, aid) => {
    try {
      const data = await Partner.nearList({
        aids: aid ?? 1,
        page: 1,
        size: 200,
        carRadius: Number(radius),
        carLocation: location,
      });

      if (data) {
        partnerListSetting(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const partnerListSetting = (data) => {
    const filter = data.filter((el) => el.useYn === 'Y');
    const list = [];
    filter.map((el) =>
      list.push({
        partnerName: `${el.partnerName} (${partnerAllType
          .filter((item) =>
            el.partnerRepairType.split(',').includes(item.value)
          )
          .map((item) => item.label)}) ${
          el.partnerShip === 'Y' ? '/ 제휴' : '/ 비제휴'
        }`,
        partnerId: el.partnerId,
        partnerRepairType: el.partnerRepairType,
        cellphone: el.cellphone,
        address: el.address,
        partnerShip: el.partnerShip,
      })
    );
    return setPartnerList(list);
  };

  const getPartnerAllList = async (aid) => {
    try {
      const data = await partnerService.list({
        aids: aid ?? 1,
        page: 1,
        size: 200,
        useYN: 'Y',
      });
      if (data) {
        setPartnerAllList(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPartnerDetail = async (id) => {
    try {
      const data = await Partner.getDetailData(id);
      if (data) {
        const partnerInfo = data.partnerInfo;
        const partnerAllList = warrantyData.partnerInfo;
        const el = partnerAllList.find(
          (partner) => Number(partner.partnerId) === partnerInfo.partnerId
        );
        if (partnerInfo) {
          let newSelectPartner = {
            partnerName: partnerInfo?.partnerName,
            partnerType: el?.partnerType.split(',') ?? '', // 진단점 P_D, 수리점 P_R, 둘다 P_DR
            claimStatusCode: el?.claimStatusCode ?? null,
            receiptDate: el?.receiptDate
              ? CommonUtil.Formatter.stringToDate(el?.receiptDate)
              : null,
            dueDate: el.dueDate
              ? CommonUtil.Formatter.stringToDate(el.dueDate)
              : '',
            paymentAmt: el?.paymentAmt ?? null,
            checkDate: el?.checkDate ?? null,
            partnerId: el?.partnerId,
            reservationDate: el?.reservationDate
              ? CommonUtil.Formatter.stringToDate(el?.reservationDate)
              : null,
            documentGroupId: el?.documentCode ?? null,
            useYn: 'Y',
            address: partnerInfo.address,
            cellphone: partnerInfo.cellphone,
            isOriginal: true,
            partnerDocuments: el?.partnerDocuments,
            partnerShip: partnerInfo?.partnerShip,
          };
          newSelectPartner.repairCodesName = filterPartnerTypeOptions(
            partnerAllType,
            partnerInfo.partnerRepairType.split(',')
          );
          newSelectPartner.repairCodes = el.repairCode
            ? el.repairCode.split(',')
            : [];

          setSelectedPartner([newSelectPartner]);
        }
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error('진단점 상세 정보 조회 실패');
    }
  };

  useEffect(() => {
    if (partner) {
      const userName = myInfo.contents?.userName?.slice(0, -4);
      const findPartner = warrantyData.partnerInfo?.find((el) =>
        el.partnerName.includes(userName)
      );
      if (findPartner) {
        getPartnerDetail(findPartner.partnerId);
      }
    }
  }, [partner, myInfo.contents, warrantyData.partnerInfo]);

  useEffect(() => {
    if (
      !!carYX &&
      typeof warrantyData.radius === 'number' &&
      !!aid &&
      !partner
    ) {
      getPartnerList(carYX, warrantyData.radius, aid);
    } else if (warrantyData.radius === '전체' && !!partnerAllList) {
      partnerListSetting(partnerAllList);
    }
  }, [carYX, warrantyData.radius, aid, partner]);

  useEffect(() => {
    if (!!aid && !oldData && !partner) {
      getPartnerAllList(aid);
    }
  }, [aid, oldData]);

  const [statusList, setStatusList] = useState([]);
  const [partnerStatusList, setPartnerStatusList] = useState([]);
  const getStatusList = async () => {
    try {
      const data = await Claim.getDocsType('Z');
      setStatusList(data.data);
      const list = data.data?.filter(
        (el) => el.value === 'Z06' || el.value === 'Z08'
      );
      setPartnerStatusList(list);
    } catch (error) {
      console.log(error);
    }
  };

  const setShopListState = useSetRecoilState(shopListState);
  const shopList = useRecoilValue(shopListState);
  const getShopList = async (eids) => {
    try {
      const data = await Shop.getList({ eids: eids });
      if (data) {
        setShopListState(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!myInfo.contents.associationInfo && !oldData) {
      getShopList(myInfo.contents.associationInfo.associationId);
    }
  }, [myInfo.contents, oldData]);

  const [insuranceList, setInsuranceList] = useState([]);
  const getInsuranceList = async () => {
    try {
      const data = await Claim.getInsurances();
      const list = data.filter(
        (el) => el.label?.includes('KB') || el.label?.includes('DB')
      );
      setInsuranceList(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!oldData) {
      getInsuranceList();
    }
    getStatusList();
  }, [oldData]);

  useEffect(() => {
    if (history.location.state) {
      setCustomerInfo(history.location.state.customerInfo);
    }
  }, [history.location.state]);

  const getDetailData = async (dataId) => {
    setLoading(true);
    try {
      const data = oldData
        ? await Claim.getDetailOld(dataId)
        : await Claim.getDetail(dataId);
      if (data) {
        if (data.claimData.carLocation) {
          setCarYX(data.claimData.carLocation);
        } else if (data.claimData.carAddress && !oldData) {
          getAddressInfo(data.claimData.carAddress);
        }
        if (data.claimData.carAddress) {
          setCarAddress(data.claimData.carAddress);
          setOriCarAddress(data.claimData.carAddress);
        }
        if (data.claimData.funnelsType) {
          const type = FUNNELS_TYPE.find(
            (el) => el.value === data.claimData.funnelsType
          );
          setFunnelsType(type ? type : { label: '', value: '' });
        }
        if (data.claimData.shopId) {
          setShopId(data.claimData.shopId);
        }
        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setCustomerInfo({
          newCarNbr: data.claimData.newCarNbr,
          customerName: data.claimData.customerName,
          customerContact: data.claimData.customerContact,
          carVIN: data.claimData.vin,
          carNbr: data.claimData.carNbr,
          association: {
            value: !!data.claimData.checkFlag
              ? data.claimData.checkFlag === 'Y'
                ? 'association'
                : 'non_association'
              : null,
          },
          warranty: {
            value: !!data.claimData.assuranceType
              ? data.claimData.assuranceType === '2'
                ? 'warranty'
                : 'warranty_s'
              : null,
          },
          carVIN: data.claimData.vin,
          receiptDate: data.claimData.receiptDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.receiptDate)
            : today,
          addType: {
            value: data.claimData.addType,
          },
          note: !!data.claimData.note ? data.claimData.note : '',
          vehicleValue: data.claimData.vehicleValue ?? '',
        });
        setWarrantyData({
          buyDate: !!data.claimData.buyDate ? data.claimData.buyDate : null,
          checkDistance: data.claimData.checkDistance,
          distanceDriven: data.claimData.distanceDriven,
          diagnosisType: data.claimData.diagnosisType
            ? data.claimData.diagnosisType
            : null,
          reservationDate: data.claimData.reservationDate,
          producingCountry: data.claimData.producingCountry,
          carName: data.claimData.carName,
          partnerInfo: data.claimData.partnerInfo
            ? data.claimData.partnerInfo
            : null,
          partnerNote: data.claimData.partnerNote,
        });
        setStatusCode(
          data.claimData.claimStatus === 'Z1'
            ? 'Z01'
            : data.claimData.claimStatus
        );
        setRepairData({
          date: data.claimData.partnerReceiptDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.partnerReceiptDate
              )
            : '',
          maintenanceCode: data.claimData.maintenanceCode
            ? data.claimData.maintenanceCode.split(',')
            : '',
          workYN: data.claimData.workYN,
          workFee: data.claimData.workFee,
          repairNote: data.claimData.repairNote,
          repairOpinion: data.claimData.repairOpinion
            ? data.claimData.repairOpinion
            : '',
        });
        setInsuranceInfo({
          payDate: data.claimData.insurancePurchaseDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.insurancePurchaseDate
              )
            : '',
          paymentAmt: data.claimData.insurancePaymentAmt, // int
          deductibleAmt: data.claimData.deductibleAmt, // int
          completeDate: data.claimData.completeDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.completeDate)
            : '',
          insurancePolicyNumber: data.claimData.insurancePolicyNumber
            ? data.claimData.insurancePolicyNumber
            : '',
          code: data.claimData.insuranceCode
            ? data.claimData.insuranceCode
            : insuranceInfo.code,
        });
      }
      if (myInfo.contents) {
        setUserNames({
          firstId: data.claimData.receiptId ?? null,
          firstName: data.claimData.receiptName
            ? data.claimData.receiptName
            : null,
          lastId: myInfo.contents.userId,
          lastName: myInfo.contents.userName,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (myInfo.contents) {
      setUserNames({
        ...userNames,
        lastId: myInfo.contents.userId,
        lastName: myInfo.contents.userName,
      });
    }
  }, [myInfo.contents]);

  const filterPartnerTypeOptions = (partnerAllType, repairCodes) => {
    if (!repairCodes) return [];
    return partnerAllType
      .filter((item) => repairCodes.includes(item.value))
      .map((item) => item.label)
      .toString();
  };

  //ANCHOR - set partner info
  useEffect(() => {
    if (!!warrantyData.partnerInfo.length && !!partnerAllList.length) {
      const data = [];
      warrantyData.partnerInfo.map((el) => {
        const partner = partnerAllList.find(
          (partner) => Number(el.partnerId) === partner.partnerId
        );
        if (partner) {
          let newSelectPartner = {
            partnerName: partner?.partnerName,
            partnerType: el?.partnerType.split(',') ?? '',
            claimStatusCode: el?.claimStatusCode ?? null,
            receiptDate: el?.receiptDate
              ? CommonUtil.Formatter.stringToDate(el?.receiptDate)
              : null,
            dueDate: el.dueDate
              ? CommonUtil.Formatter.stringToDate(el.dueDate)
              : '',
            paymentAmt: el?.paymentAmt ?? null,
            checkDate: el?.checkDate ?? null,
            partnerId: partner.partnerId,
            reservationDate: el?.reservationDate
              ? CommonUtil.Formatter.stringToDate(el?.reservationDate)
              : null,
            documentGroupId: el?.documentCode ?? null,
            useYn: 'Y',
            address: partner.address,
            cellphone: partner.cellphone,
            isOriginal: true,
            partnerDocuments: el.partnerDocuments,
            partnerShip: partner?.partnerShip,
          };
          newSelectPartner.repairCodesName = filterPartnerTypeOptions(
            partnerAllType,
            partner.partnerRepairType.split(',')
          );
          newSelectPartner.repairCodes = el.repairCode
            ? el.repairCode.split(',')
            : [];
          return data.push(newSelectPartner);
        }
      });
      setSelectedPartner(data);
    }
  }, [warrantyData.partnerInfo, partnerAllList, partnerAllType]);

  useEffect(() => {
    if (docs) {
      setFileItems(docs);
      setCopyFile(docs);
    }
  }, [docs]);

  const [imgConfirm, setimgConfirm] = useState(false);

  //ANCHOR -  클레임 POST, PUT
  const registerClaim = async (type) => {
    if (oldData) {
      return;
    }

    if (partner) {
      // if (statusCode !== 'Z06' && statusCode !== 'Z07') {
      //   return window.cerp.toast.warn(
      //     '업무 진행상태 미선택',
      //     '입고점검완료 또는 수리완료 선택해주세요.'
      //   );
      // }

      if (!imgConfirm) {
        return window.cerp.toast.warn(
          '필수서류 누락',
          '입고점검 사진을 다시 확인해주세요.'
        );
      }
      setLoading(true);
    }

    const fileData = [];
    const files = [];
    const pfiles = [];

    let fileDataList = [];
    let filesList = [];

    if (type === '저장') {
      fileItems.map((el) =>
        fileData.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      fileItems.map((el) => files.push(el.file));
    } else if (type === '수정') {
      const del = [];
      if (!!deleteImgs.length) {
        const imgs = deleteImgs.filter((el) => !!el.imgId);

        if (!!imgs.length) {
          imgs.map((el) =>
            del.push({
              imgId: el.imgId,
              documentCode: el.documentCode,
              state: 'D',
            })
          );
        }
        fileDataList = del;
      }

      const findFile = fileItems.find((el) => el.file);
      const findImgId = fileItems.find((el) => el.imgId);
      const idFile = [];
      fileItems.filter((el) => el.imgId && idFile.push(el));
      const imgFile = [];
      fileItems.filter((el) => el.file && imgFile.push(el));

      if (findFile && !findImgId) {
        fileItems.map((el) =>
          fileDataList.push({
            documentCode: el.documentCode,
            state: el.state,
          })
        );
        fileItems.map((el) => filesList.push(el.file));
      } else if (findFile && findImgId) {
        imgFile.map((el) =>
          fileDataList.push({
            documentCode: el.documentCode,
            state: el.state,
          })
        );
        const { fileData, files } =
          CommonUtil.File.convertForServerClaim(deleteImgs);
        files.map((el) => filesList.push(el));
        imgFile.map((el) => filesList.push(el.file));
      }
    }

    let partnerFiles = [];

    const modifyPartners = selectedPartner.map((partner) => {
      const { fileData, files } = CommonUtil.File.convertForServerClaimPartner(
        partner.partnerDocuments
      );
      partner.partnerDocuments = fileData;
      files.map((el) => partnerFiles.push(el));
      return partner;
    });

    let partnerInfoData = [];
    if (!!modifyPartners) {
      partnerInfoData = modifyPartners.map((el) => ({
        partnerName: el?.partnerName?.split(' (')[0],
        carNbr: customerInfo.carNbr,
        statementNumber: !!carDetailData
          ? carDetailData?.statementNumber
          : null,
        checkDate: !!carDetailData ? carDetailData.checkDate : null,
        partnerId: el.partnerId,
        repairCode: el.repairCodes?.toString(),
        reservationDate: !!el.reservationDate
          ? CommonUtil.Formatter.dateToString(el.reservationDate)
          : null,
        useYn: el.useYn,
        documentGroupId: detailData?.documentGroupId ?? null,
        fileData: el.partnerDocuments ?? [],
        partnerType: 'P_DR',
        claimStatusCode: el?.claimStatusCode ?? null,
        receiptDate: !!el.receiptDate
          ? CommonUtil.Formatter.dateToString(el.receiptDate)
          : null,
        dueDate: !!el?.dueDate ? dayjs(el?.dueDate).format('YYYYMMDD') : null,
        paymentAmt: !!el?.paymentAmt ? Number(el?.paymentAmt) : null,
        partnerShip: el.partnerShip ?? 'N',
      }));
    }

    let coordinates = '';
    if (type === '수정') {
      if (carAddress !== '') {
        if (oriCarAddress !== carAddress) {
          coordinates = await getAddressInfo(carAddress);
          if (!coordinates) {
            setLoading(false);
            window.cerp.toast.warn(
              '존재하지 않는 주소입니다. 주소를 확인해주세요.'
            );
            return;
          }
        } else {
          coordinates = carYX; // 동일한 주소
        }
      }
    }

    const Data = {
      dataId: detailData?.dataId ?? null,
      shopId: !!shopId ? shopId : null,
      associationId: aid,
      addType:
        customerInfo?.addType?.value === 'null'
          ? null
          : customerInfo?.addType?.value, // B 법인, L 리스, F 외판 opt
      funnelsType: funnelsType.value, // W or A
      claimStatus: statusCode,
      customerName: customerInfo?.customerName
        ? CommonUtil.Formatter.removeSpaces(customerInfo?.customerName)
        : '',
      customerContact: customerInfo?.customerContact?.replace(/[^0-9]/g, ''),
      checkFlag: customerInfo?.association.value === 'association' ? 'Y' : 'N',
      assuranceType: customerInfo?.warranty.value === 'warranty' ? '2' : '1',
      statementNumber: !!carDetailData ? carDetailData?.statementNumber : null, // opt
      carNbr: customerInfo.carNbr ?? null,
      vin: !!customerInfo?.carVIN ? customerInfo?.carVIN : null,
      newCarNbr: customerInfo.newCarNbr
        ? customerInfo.newCarNbr
        : detailData.newCarNbr
        ? detailData.newCarNbr
        : null,
      vehicleValue: !!customerInfo.vehicleValue
        ? Number(customerInfo.vehicleValue)
        : null,
      checkDate: !!carDetailData ? carDetailData?.checkDate : null, // opt
      buyDate:
        warrantyData.buyDate && typeof warrantyData.buyDate !== 'string'
          ? CommonUtil.Formatter.dateToString(warrantyData.buyDate)
          : !!warrantyData.buyDate
          ? warrantyData.buyDate
          : null,
      checkDistance: warrantyData.checkDistance
        ? Number(warrantyData.checkDistance)
        : null, //vehicleInformation > TRVL_DSTNC
      distanceDriven: warrantyData.distanceDriven
        ? Number(warrantyData.distanceDriven)
        : null,
      producingCountry: warrantyData.producingCountry
        ? warrantyData.producingCountry
        : null, // D or F // opt
      carName: warrantyData.carName ? warrantyData.carName : null,
      carAddress: carAddress ? carAddress : null,
      carLocation: coordinates, //Opt
      diagnosisType:
        warrantyData.diagnosisType &&
        typeof warrantyData.diagnosisType === 'string'
          ? warrantyData.diagnosisType
          : warrantyData.diagnosisType && !!warrantyData.diagnosisType.length
          ? warrantyData.diagnosisType.join()
          : null, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId
        ? detailData?.consultationGroupId
        : null,
      partnerInfo: !!partnerInfoData.length ? partnerInfoData : null, //Opt 진단점 목록
      receiptId: userNames.firstId ? userNames.firstId : myInfo.contents.userId,
      receiptName: userNames.firstName
        ? userNames.firstName
        : myInfo.contents.userName,
      receiptDate: customerInfo.receiptDate
        ? dayjs(customerInfo.receiptDate).format('YYYYMMDD')
        : dayjs(today).format('YYYYMMDD'),
      documentGroupId: type === '저장' ? null : detailData?.documentGroupId, //Opt
      note: customerInfo.note ?? null, //Opt
      partenerReceiptDate: detailData?.partenerReceiptDate ?? null, //Opt 수리 입고일자
      maintenanceCode: !!repairData.maintenanceCode.length
        ? repairData.maintenanceCode.join()
        : null,
      workYN: repairData.workYN ? repairData.workYN : null, //Opt 수리여부
      workFee: repairData.workFee ? Number(repairData.workFee) : null, //Opt 협의비용
      insuranceCode: insuranceInfo.code, // opt 보험코드
      insurancePolicyNumber: insuranceInfo.insurancePolicyNumber ?? null, //opt 증권번호
      insurancePurchaseDate: insuranceInfo.insurancePolicyNumber ?? null, //Opt 보험료 지급일
      insurancePaymentAmt: insuranceInfo.paymentAmt ?? null, //Opt 보험료
      deductibleAmt: insuranceInfo.deductibleAmt ?? null, // opt 자기부담금
      partnerNote: warrantyData.partnerNote ? warrantyData.partnerNote : null, //Opt
      repairNote: repairData.repairNote ? repairData.repairNote : null,
      repairOpinion: repairData.repairOpinion ? repairData.repairOpinion : null, // Opt 소견내용
      completeYN: insuranceInfo.completeDate ? 'Y' : 'N', //Opt 완료여부
      completeDate: insuranceInfo.completeDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.completeDate)
        : null, //Opt 완료일
      completeLoginId: partner ? null : myInfo.contents.loginId, //Opt 로그인유저 아이디
      completeName: partner ? null : myInfo.contents.userName, //Opt 로그인유저 이름
      completeLoginContact: partner ? null : myInfo.contents.contact, //opt 로그인유저 연락처
      fileData: type === '저장' ? fileData : fileDataList, //Otp
    };

    // return console.log(Data, filesList, fileDataList);
    const checkData =
      !!Data.funnelsType &&
      !!Data.checkFlag &&
      !!Data.assuranceType &&
      !!Data.customerName &&
      !!Data.customerContact &&
      !!Data.carNbr &&
      !!Data.receiptDate;

    if (!checkData) {
      setLoading(false);
      window.cerp.toast.warn('필수 입력값을 입력해주세요.');
      return;
    }

    try {
      if (type === '저장') {
        const data = await Claim.registerClaim(Data, files, pfiles);
        if (data) {
          window.cerp.toast.success('등록이 완료 되었습니다.');
          history.replace(
            `/compensation/accident/claim/detail/${data.data.dataId}`
          );
          sessionStorage.removeItem('images');
          setLoading(false);
        }
      } else if (type === '수정') {
        const data = await Claim.updateClaim(Data, filesList, partnerFiles);
        if (data) {
          window.cerp.toast.success('저장이 완료 되었습니다.');
          getDetailData(data.dataId);
          setDeleteImgs([]);
          setPartnerDeleteImgs([]);
          sessionStorage.removeItem('images');
          setLoading(false);
          setNewStatusCode(null);
          setFileItems([]);
        }
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(
        '저장 실패',
        '페이지 새로고침 후 다시 시도해주세요.'
      );
      setLoading(false);
    }
  };

  const deleteClaim = async (id) => {
    try {
      const data = await Claim.deleteClaim(id);
      // console.log(data);
      if (data) {
        window.cerp.toast.success('삭제가 완료 되었습니다.');
        history.push('/compensation/accident/list');
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error?.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    if (!!id && id !== 'new') {
      getDetailData(id);
    }
  }, [id]);

  const NEW = id === 'new';

  const handleInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    if (name === 'vehicleValue') {
      setCustomerInfo({ ...customerInfo, [name]: value?.replaceAll(',', '') });
    } else {
      setCustomerInfo({ ...customerInfo, [name]: value });
    }
  };

  const checkWarranty = async (carNum, statementNumber, vin) => {
    setLoading(true);
    try {
      const data = await Claim.ClaimPerfomanceCheck(
        carNum,
        statementNumber,
        vin
      );
      if (data) {
        if (data.length === 1) {
          const info = data[0];
          setCarDetailData(info);
          setCustomerInfo({
            ...customerInfo,
            association: { value: 'association' },
            carVIN: info.vin,
          });
          setShopId(info.shopId);
          setCarInfo(data);
        } else {
          setCarInfo(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerInfo.carNbr && !oldData && !partner) {
      checkWarranty(
        customerInfo.carNbr,
        detailData?.statementNumber,
        customerInfo.carVIN
      );
    }
  }, [customerInfo.carNbr, detailData?.statementNumber, partner]);

  const getList = async (id) => {
    try {
      const data = await Claim.getConsultation({
        page: 1,
        size: 999,
        consultationGroupId: id,
      });
      setConsultationList(data.list);
    } catch (error) {
      console.log(error);
    }
  };

  const registerConsultation = async (text) => {
    const id = detailData?.consultationGroupId;
    try {
      const data = await Claim.posetConsultation({
        consultationGroupId: id,
        consultationDetails: text,
      });
      if (data) {
        getList(id);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error?.response.data.error?.message);
    }
  };

  const changeConsultation = async (dataId, text, useYN) => {
    setLoading(true);
    try {
      const data = await Claim.updateConsultation({
        dataId: dataId,
        consultationDetails: text,
        useYN: useYN,
      });
      if (data) {
        if (useYN === 'Y') {
          window.cerp.toast.success('수정이 완료 되었습니다.');
        } else {
          window.cerp.toast.success('삭제가 완료 되었습니다.');
        }
        getList(detailData?.consultationGroupId);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!!detailData?.consultationGroupId && !partner) {
      getList(detailData?.consultationGroupId);
    }
  }, [detailData?.consultationGroupId, partner]);

  const saveFilesAsZip = async () => {
    window.cerp.toast.info('다운로드를 시작합니다.');

    const partnerFiles = [];
    selectedPartner.map((partner) =>
      partner.partnerDocuments.map((doc) => partnerFiles.push(doc))
    );

    const folderName = customerInfo.carNbr;
    const files = fileItems.concat(partnerFiles);
    const haveIds = [];
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const imagePromises = files
      .filter((file) => file.documentCode !== 'C_FRONT')
      .map(async (file, index) => {
        let docType = DOC_TYPE.find((doc) => doc.value === file.documentCode);
        if (file.documentCode === 'C_ORIGINALDOCS') {
          docType = {
            ...docType,
            category: '매매상사접수서류',
            label: '자동차등록원부(갑)',
          };
        }
        const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(
          file.originalFileName
        );
        const fileExtension = isImage
          ? '.jpg'
          : file.originalFileName.slice(file.originalFileName.lastIndexOf('.'));
        const fileName = docType
          ? `${docType.category}/${index + 1}_${docType.label}.${fileExtension}`
          : `${index + 1}_${file.documentCode}.${fileExtension}`;
        const imgBlob = await Claim.getBlobFromUrl(file.filePath);
        imagesFolder.file(fileName, imgBlob, { binary: true });
      });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.success('다운로드가 완료되었습니다.');
  };

  const postSms = async (title) => {
    setLoading(true);
    const noUrl =
      title === '고객 서류 재요청 안내' ||
      title === '보증 거절 안내' ||
      title === '입고점 정보 안내';

    const postData = {
      msgType: smsData.msgType ? smsData.msgType : 'lms',
      msgSubType: smsData.msgType === 'at' ? smsData.msgSubType : null, //opt
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr?.replaceAll('-', ''),
      subject: smsData.subject, // opt
      contents: smsData.contents,
      fileData: smsData.fileData,
      url: noUrl ? null : smsData.url,
      msgAuthType: 'SR',
    };

    try {
      const data = await Claim.postSMS(postData);
      if (data) {
        window.cerp.toast.success('메세지 전송이 완료되었습니다.');
        registerConsultation(title);
        setShowSmsModal(false);
        setLoading(false);
        if (title === '수리승인 및 양식 다운로드 안내') {
          handleToggleButton('Z07', true);
        } else if (
          title === '입고점 정보 안내' ||
          title === '진단점 고객정보 안내'
        ) {
          handleToggleButton('Z05', true);
        } else if (title === '보증 거절 안내') {
          handleToggleButton('Z04', true);
        }
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error?.response?.data.error?.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const postPushMessage = async (pushType, missingDocs = null) => {
    setPushLoading(true);
    const id =
      pushType === 'C'
        ? `${detailData.carNbr}_${detailData.customerContact}`
        : partnerInfo?.loginId;

    const isDocType =
      showPushType === 'CENTER_DOCS' ? CENTER_DOCS : REPAIR_DOCS;

    let labels;
    let missingDocsMessage;
    if (missingDocs) {
      labels = missingDocs.map((val) => {
        const doc = isDocType.find((item) => item.value === val);
        return doc ? doc.label : val;
      });

      missingDocsMessage =
        labels.length > 0
          ? `${customerInfo?.carNbr} 차량 | ${labels.join(', ')} 서류 미비 알림`
          : `${customerInfo?.carNbr} 차량 | 서류 미비 알림`;
    }

    try {
      const reqData = {
        pushType: pushType,
        regId: id,
        msgType: pushType === 'C' ? '1' : '2',
        dataId: detailData.dataId,
        sendMsg: pushType === 'C' ? null : missingDocsMessage,
      };

      const data = await Claim.pushMessage(reqData);
      if (data) {
        window.cerp.toast.success('푸시 전송이 완료되었습니다.');
        const text =
          pushType === 'C' ? '고객 입고점 안내 앱푸시' : missingDocsMessage;
        registerConsultation(text);
      }
    } catch (error) {
      window.cerp.toast.error(error?.response?.data?.error?.message);
    } finally {
      setPushLoading(false);
      setShowPushModal(false);
    }
  };

  useEffect(() => {
    if (!!selectedPartner.length) {
      const initValue = 0;
      const sumValeu = selectedPartner
        .filter((el) => el.useYn === 'Y')
        ?.reduce(
          (acc, cur) =>
            Number(acc) + Number(cur?.paymentAmt ? cur?.paymentAmt : 0),
          initValue
        );

      setInsuranceInfo({ ...insuranceInfo, paymentAmt: sumValeu });
    } else {
      setInsuranceInfo({ ...insuranceInfo, paymentAmt: 0 });
    }
  }, [selectedPartner.length, selectedPartner, warrantyData.partnerInfo]);

  const [rowClick, setRowClick] = useState(true);
  const [selectedCarInfo, setSelectedCarInfo] = useState(null);

  return (
    <>
      <div className="px-3 py-2 fixed surface-200 w-full z-5 border-bottom-1 border-400">
        <div className="flex md:justify-content-between justify-content-start">
          <div className="flex align-items-center gap-2 ">
            <div className="font-bold ml-2 text-xl md:block hidden">
              클레임 관리
            </div>
          </div>
          <div className="flex gap-4">
            {/* {partner ? null : ( */}
            <div className="flex gap-2">
              <div className="flex align-items-center">*업무 진행상태 :</div>
              <Dropdown
                // disabled={partner}
                value={statusCode}
                onChange={(e) => {
                  setStatusCode(e.target.value);
                }}
                options={partner ? partnerStatusList : statusList}
                optionLabel="label"
                placeholder="상태 선택"
                className="w-full md:w-16rem font-bold overflow-hidden"
              />
            </div>
            {/* )} */}
            <Button.Default
              className="sm:text-lg sm:text-base"
              icon="pi pi-save"
              label={'저장'}
              disabled={oldData}
              onClick={() => registerClaim(NEW ? '저장' : '수정')}
            />
            <Button.Default
              className="p-button-outlined p-button-danger"
              icon="pi pi-times"
              label=""
              onClick={() => {
                sessionStorage.removeItem('images');
                sessionStorage.removeItem('data');
                if (oldData) {
                  history.push('/compensation/accident/oldlist');
                } else {
                  history.push('/compensation/accident/list');
                }
              }}
            />
          </div>
        </div>
      </div>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <div className="p-3 pt-8 gap-2 h-screen" id="claim-dialog">
          <div className="grid pb-3">
            <div className={partner ? 'col-12' : 'md:col-8 lg:col-9 col-12'}>
              {partner ? null : (
                <Panel
                  className="mb-3"
                  header={
                    <div className="flex justify-content-between align-items-center">
                      <div>1. 보험 정보</div>
                    </div>
                  }
                >
                  <div className="grid">
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <div className="font-bold mb-2">
                        <label htmlFor="">보험사</label>
                      </div>
                      <Dropdown
                        value={insuranceInfo.code}
                        onChange={(e) =>
                          setInsuranceInfo({
                            ...insuranceInfo,
                            code: e.value,
                          })
                        }
                        options={insuranceList}
                        optionLabel="label"
                        placeholder="보험사 선택"
                        className="w-full"
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <div className="font-bold mb-2">
                        <label htmlFor="">성능점검장</label>
                      </div>
                      <Dropdown
                        value={shopId}
                        onChange={(e) => {
                          setShopId(e.value);
                        }}
                        options={shopList}
                        optionLabel="label"
                        placeholder="점검장 선택"
                        className="w-full"
                      />
                    </div>

                    <div className="field col-12 sm:col-6 lg:col-3">
                      <div className=" font-bold mb-2">
                        <label htmlFor="">최초 작성자</label>
                      </div>
                      <InputText
                        readOnly
                        value={userNames.firstName || ''}
                        className=" w-full"
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <div className="font-bold mb-2">
                        <label htmlFor="">최종 작성자</label>
                      </div>
                      <InputText
                        readOnly
                        value={userNames.lastName || ''}
                        className=" w-full"
                      />
                    </div>
                  </div>
                </Panel>
              )}
              <Panel
                header={
                  <div className="flex justify-content-between align-items-center">
                    <div>2. 접수 내역</div>
                    <div className="flex gap-2">
                      <Button.Default
                        label="앱 다운로드 SMS 재전송"
                        onClick={
                          customerInfo.customerName &&
                          customerInfo.customerContact
                            ? () => {
                                setSmsData({
                                  ...smsData,
                                  destAddr: `${customerInfo.customerContact}`,
                                });
                                setShowSmsModal(true);
                                setShowSmsType('APP_DOWNLOAD');
                              }
                            : () =>
                                window.cerp.toast.warn(
                                  '고객명, 고객연락처를 입력하세요.'
                                )
                        }
                        disabled={partner}
                      />
                      <Button.Default
                        label="차량정보 조회"
                        icon="pi pi-search"
                        className=""
                        onClick={() => setShowCarInfoModal(true)}
                        disabled={!carInfo?.length}
                      />
                    </div>
                  </div>
                }
              >
                <div className="grid">
                  <div className="md:col-6 col-12 p-3">
                    <div className="p-card-title field text-base">기본정보</div>
                    <div className="field grid align-items-center">
                      <div className="col-3 font-bold">접수자명</div>
                      <div className="col">
                        <InputText
                          value={
                            customerInfo?.customerName
                              ? CommonUtil.Formatter.removeSpaces(
                                  customerInfo?.customerName
                                )
                              : ''
                          }
                          onChange={(e) => handleInput(e)}
                          onClick={() => {
                            !NEW &&
                              !partner &&
                              window.cerp.toast.warn(
                                '앱에서 등록된 정보입니다. 수정하시겠습니까?'
                              );
                          }}
                          name="customerName"
                          className=" w-full"
                          placeholder="접수자명 입력"
                          readOnly={partner}
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3 font-bold">접수자 연락처</div>
                      <div className="col">
                        <InputText
                          value={
                            CommonUtil.Formatter.phone(
                              customerInfo?.customerContact
                            ) || ''
                          }
                          maxLength={13}
                          onChange={(e) => handleInput(e)}
                          onClick={() => {
                            !NEW &&
                              !partner &&
                              window.cerp.toast.warn(
                                '앱에서 등록된 정보입니다. 수정하시겠습니까?'
                              );
                          }}
                          name="customerContact"
                          className=" w-full"
                          placeholder="연락처 입력"
                          readOnly={partner}
                        />
                      </div>

                      {partner ? null : (
                        <>
                          <div className="font-bold">비밀번호</div>
                          <div className="col">
                            <InputText
                              disabled
                              value={detailData?.certCode || ''}
                              className=" w-full"
                              placeholder="앱에서 등록한 비밀번호"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3 font-bold">접수일자</div>
                      <div className="col">
                        <Calendar
                          value={customerInfo?.receiptDate || ''}
                          onChange={(e) => handleInput(e)}
                          dateFormat="yy-mm-dd"
                          name="receiptDate"
                          maxDate={today}
                          className="w-full"
                          placeholder="접수일자 선택"
                          disabled={partner}
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3 font-bold">접수방법</div>
                      <div className="col">
                        <Dropdown
                          value={funnelsType.value}
                          onChange={(e) => {
                            const type = FUNNELS_TYPE.find(
                              (el) => el.value === e.target.value
                            );
                            setFunnelsType(type);
                          }}
                          options={FUNNELS_TYPE}
                          optionLabel="label"
                          placeholder="접수유형 선택"
                          className="w-full"
                          disabled={partner}
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center mb-0">
                      <div className="col-3 font-bold">증상</div>
                      <div className="col">
                        <InputTextarea
                          value={customerInfo.note || ''}
                          onChange={(e) => handleInput(e)}
                          name="note"
                          className=" w-full"
                          placeholder="증상 입력"
                          readOnly={partner}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="md:col-6 col md:border-left-1 sm:border-left-0 border-gray-300 p-3">
                    <div className="p-card-title field text-base">차량정보</div>
                    <div className="field grid align-items-center">
                      <div className="col-3 font-bold">차량번호</div>
                      <div className="col">
                        <InputText
                          value={
                            NEW
                              ? customerInfo?.carNbr
                              : detailData?.carNbr || ''
                          }
                          onChange={(e) => handleInput(e)}
                          name="carNbr"
                          className=" w-full font-bold"
                          readOnly={partner}
                        />
                      </div>

                      <div className="font-bold">차량번호(변경)</div>
                      <div className="col">
                        <InputText
                          value={customerInfo?.newCarNbr || ''}
                          onChange={(e) => handleInput(e)}
                          name="newCarNbr"
                          className=" w-full"
                          placeholder="차량번호 변경된 경우"
                          readOnly={partner}
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3 font-bold">차대번호</div>
                      <div className="col">
                        <InputText
                          value={customerInfo?.carVIN || ''}
                          onChange={(e) => handleInput(e)}
                          name="carVIN"
                          className=" w-full font-bold "
                          placeholder="차대번호 입력"
                          readOnly={partner}
                        />
                      </div>
                    </div>

                    <div className="field grid">
                      <div className="col-3 font-bold">보증가능</div>
                      <div className="col">
                        <RadioButton
                          inputId="association"
                          name="customerSearchBy"
                          value="association"
                          className="mr-2"
                          checked={
                            customerInfo?.association?.value === 'association'
                          }
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              association: { value: e.value },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="association">SR 진단차량</label>
                        <RadioButton
                          inputId="non_association"
                          name="customerSearchBy"
                          value="non_association"
                          className="ml-4 mr-2"
                          checked={
                            customerInfo?.association?.value ===
                            'non_association'
                          }
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              association: { value: e.value },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="non_association">미 진단차량</label>
                      </div>
                    </div>
                    <div className="field grid ">
                      <div className="col-3 font-bold">보증구분</div>
                      <div className="col">
                        <RadioButton
                          inputId="warranty"
                          name="customerSearchBy"
                          value="warranty"
                          className="mr-2"
                          checked={customerInfo?.warranty?.value === 'warranty'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              warranty: { value: e.value },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="warranty">보험사 보증</label>
                        <RadioButton
                          inputId="warranty_s"
                          name="customerSearchBy"
                          value="warranty_s"
                          className="mr-2 ml-4 "
                          checked={
                            customerInfo?.warranty?.value === 'warranty_s'
                          }
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              warranty: { value: e.value },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="warranty_s">자가보증</label>
                      </div>
                    </div>
                    <div className="field grid ">
                      <div className="col-3 font-bold">추가구분</div>
                      <div className="col">
                        <RadioButton
                          inputId="addTypeB"
                          name="customerSearchBy"
                          value="B"
                          className="mr-2"
                          checked={customerInfo?.addType?.value === 'B'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: e.value },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="addTypeB">법인</label>
                        <RadioButton
                          inputId="addTypeL"
                          name="customerSearchBy"
                          value="L"
                          className="mr-2 ml-3"
                          checked={customerInfo?.addType?.value === 'L'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: e.value },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="addTypeL">리스</label>
                        <RadioButton
                          inputId="addTypeF"
                          name="customerSearchBy"
                          value="F"
                          className="mr-2 ml-3"
                          checked={customerInfo?.addType?.value === 'F'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: e.value },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="addTypeF">외판</label>
                        <RadioButton
                          inputId="addType"
                          name="customerSearchBy"
                          value="null"
                          className="mr-2 ml-3"
                          checked={
                            customerInfo?.addType?.value === null ||
                            customerInfo?.addType.value === ''
                          }
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: null },
                            })
                          }
                          disabled={partner}
                        />
                        <label htmlFor="addType">없음</label>
                      </div>
                    </div>
                    <div className="field grid mb-0 align-items-center mb-2">
                      <div className="col-3 font-bold">차량위치</div>
                      <div className="col">
                        <InputText
                          readOnly={partner}
                          value={carAddress || ''}
                          onChange={(e) => setCarAddress(e.target.value)}
                          className=" w-full"
                          placeholder="고객 차량위치 입력"
                          disabled={partner}
                        />
                      </div>
                    </div>
                  </div>
                  {/* </Card> */}
                </div>
              </Panel>
              {partner ? null : (
                <>
                  <CustomerDocument
                    deleteImgs={deleteImgs}
                    setDeleteImgs={setDeleteImgs}
                    fileItems={fileItems}
                    setFileItems={setFileItems}
                    copyFile={copyFile}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    carNbr={customerInfo?.carNbr}
                    checked={toggleStates}
                    onToggle={handleToggleButton}
                  />
                  <WarrantyDiagnosis
                    postPushMessage={postPushMessage}
                    pushLoading={pushLoading}
                    customerInfo={customerInfo}
                    handleInput={handleInput}
                    detailData={detailData}
                    carInfo={carDetailData}
                    setWarrantyData={setWarrantyData}
                    warrantyData={warrantyData}
                    partnerAllType={partnerAllType}
                    partnerAllList={partnerAllList}
                    partnerList={partnerList}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    setSmsData={setSmsData}
                    smsData={smsData}
                    checked={toggleStates}
                    onToggle={handleToggleButton}
                    selectedPartner={selectedPartner}
                    setSelectedPartner={setSelectedPartner}
                    registerClaim={registerClaim}
                  />
                </>
              )}

              <PartnerDocument
                setPartnerImgConfirm={setimgConfirm}
                setPartnerInfo={setPartnerInfo}
                deleteImgs={partnerDeleteImgs}
                setDeleteImgs={setPartnerDeleteImgs}
                fileItems={partnerFile}
                setFileItems={setPartnerFile}
                copyFile={copyFile}
                carNbr={customerInfo?.carNbr}
                checked={toggleStates}
                onToggle={handleToggleButton}
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
                repairData={repairData}
                setRepairData={setRepairData}
                setShowSmsType={setShowSmsType}
                setShowSmsModal={setShowSmsModal}
                setSmsData={setSmsData}
                setShowPushModal={setShowPushModal}
                setShowPushType={setShowPushType}
                smsData={smsData}
              />

              {partner ? null : (
                <>
                  <Panel
                    header={
                      <div className="flex justify-content-between align-items-center">
                        <div>6. 마감정보</div>
                      </div>
                    }
                    className="pt-3"
                  >
                    <div className="grid lg:col-12 col p-3">
                      <div className=" grid lg:col-4 col align-items-center">
                        <div className="w-6rem font-bold">
                          <label htmlFor="">보험사</label>
                        </div>
                        <div className="w-18rem">
                          <Dropdown
                            value={insuranceInfo.code || ''}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                code: e.value,
                              })
                            }
                            options={insuranceList}
                            optionLabel="label"
                            placeholder="보험사 선택"
                            className="w-full"
                          />
                        </div>
                      </div>

                      <div className=" grid lg:col-4 col align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">증권번호</label>
                        </div>
                        <div className="w-18rem">
                          <InputText
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                insurancePolicyNumber: e.target.value,
                              })
                            }
                            value={insuranceInfo.insurancePolicyNumber || ''}
                            className=" w-full"
                          />
                        </div>
                      </div>
                      <div className=" grid lg:col-4 col align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">총지급액</label>
                        </div>
                        <div className="w-18rem">
                          <InputText
                            readOnly={customerInfo.addType.value !== 'F'}
                            value={
                              !!insuranceInfo.paymentAmt
                                ? insuranceInfo.paymentAmt
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    ?.toString()
                                : ''
                            }
                            className=" w-full"
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                paymentAmt: e.target.value?.replaceAll(',', ''),
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className=" grid lg:col-4 col align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">자기부담금</label>
                        </div>
                        <div className="w-18rem">
                          <InputText
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                deductibleAmt: e.target.value,
                              })
                            }
                            value={insuranceInfo.deductibleAmt || ''}
                            className=" w-full"
                          />
                        </div>
                      </div>
                      <div className=" grid lg:col-4 col align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">마감일</label>
                        </div>
                        <div className="w-18rem">
                          <Calendar
                            value={insuranceInfo.completeDate}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                completeDate: e.target.value,
                              })
                            }
                            dateFormat="yy-mm-dd"
                            showButtonBar
                            showIcon
                            mask="9999-99-99"
                            placeholder="마감일 선택"
                            className="w-18rem"
                          />
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <div className="flex justify-content-between pt-4	pb-2">
                    <Button.Default
                      className="p-button-danger"
                      icon="pi pi-trash"
                      label="클레임 삭제"
                      onClick={() => setShowModal(true)}
                    />
                    <Button.Default
                      className=""
                      icon="pi pi-download"
                      label="이미지 전체 다운로드"
                      onClick={() => saveFilesAsZip()}
                    />
                  </div>
                </>
              )}
            </div>
            {partner ? null : (
              <div className="md:col-4 col lg:col-3 block md:fixed md:right-0 md:z-5 top-10">
                <Counseling
                  NEW={NEW}
                  consultationList={consultationList}
                  registerConsultation={registerConsultation}
                  changeConsultation={changeConsultation}
                />
              </div>
            )}
          </div>
        </div>
      </BlockUI>

      {showModal && (
        <Modal.Default
          onHide={() => setShowModal(!showModal)}
          header={'클레임 삭제'}
          widthClass="30rem"
        >
          <div className="pb-4">클레임을 정말 삭제하시겠습니까?</div>
          <div className="flex gap-4 justify-content-center	">
            <Button.Default
              label="삭제"
              className=" p-button-danger"
              onClick={() => {
                deleteClaim(detailData?.dataId);
                setShowModal(false);
              }}
            />
            <Button.Default
              label="취소"
              className=""
              onClick={() => setShowModal(false)}
            />
          </div>
        </Modal.Default>
      )}
      {showCarInfoModal && (
        <Modal.Default
          onHide={() => setShowCarInfoModal(!showCarInfoModal)}
          header={'차량정보 확인'}
          widthClass="80rem"
        >
          <div className="pb-4">정보를 반영할 차량을 선택해주세요.</div>
          <div className="pb-4">
            <DataTable
              value={carInfo}
              selectionMode={rowClick ? null : 'radiobutton'}
              selection={selectedCarInfo}
              onSelectionChange={(e) => setSelectedCarInfo(e.value)}
              dataKey="dataId"
              resizableColumns
              showGridlines
            >
              <Column selectionMode="single" headerStyle={{ width: '2rem' }} />
              {columns.map(({ field, header }) => {
                return (
                  <Column
                    align={'center'}
                    key={field}
                    field={field}
                    header={header}
                  />
                );
              })}
              <Column
                align={'center'}
                field={'checkDate'}
                header="점검일자"
                body={({ checkDate }) =>
                  CommonUtil.Formatter.stringToDayForm(checkDate)
                }
              />
              <Column
                align={'center'}
                field={'assuranceType'}
                header="보증구분"
                body={({ assuranceType }) =>
                  assuranceType === '1' ? '자가보증' : '보험사 보증'
                }
              />
              {/* <Column
                align={'center'}
                field={'isRecheck'}
                header="재성능여부"
              /> */}
            </DataTable>
          </div>
          <div className="flex gap-4 justify-content-center	">
            <Button.Default
              label="적용"
              icon="pi pi-check"
              className=""
              onClick={() => {
                setCarDetailData(selectedCarInfo);
                setCustomerInfo({
                  ...customerInfo,
                  carVIN: selectedCarInfo.vin,
                  warranty: {
                    value:
                      selectedCarInfo.assuranceType === '2'
                        ? 'warranty'
                        : 'warranty_s',
                  },
                });
                setShopId(selectedCarInfo.shopId);
                setShowCarInfoModal(false);
                setSelectedCarInfo(null);
              }}
              disabled={!selectedCarInfo}
            />
          </div>
        </Modal.Default>
      )}

      {showSmsMoal && showSmsType && (
        <SendSMS
          onHide={() => setShowSmsModal(!showSmsMoal)}
          smsData={smsData}
          setSmsData={setSmsData}
          customerInfo={customerInfo}
          showSmsType={showSmsType}
          postSms={postSms}
        />
      )}
      {showPushModal && showPushType && (
        <SendPush
          showPushType={showPushType}
          customerInfo={customerInfo}
          onHide={() => setShowPushModal(!showPushModal)}
          postPushMessage={postPushMessage}
          pushLoading={pushLoading}
        />
      )}
    </>
  );
}

export default ClaimDialog;

const FUNNELS_TYPE = [
  { value: 'WC', label: '전화 / 소비자' },
  { value: 'WS', label: '전화 / 딜러' },
  { value: 'WE', label: '전화 / 기타' },
  { value: 'AC', label: '앱 / 소비자' },
  { value: 'AS', label: '앱 / 딜러' },
  { value: 'AE', label: '앱 / 기타' },
];

const columns = [
  { field: 'licensePlateNumber', header: '차량번호' },
  { field: 'vin', header: '차대번호' },
  { field: 'cnm', header: '차량명' },
  // { field: 'checkDate', header: '점검일자' },
  { field: 'shopName', header: '점검장명' },
];
