import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Button from '../../components/Atoms/Button';
import Claim from '../../services/ClaimService';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import JSZip from 'jszip';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DOC_TYPE } from '../../constants/Constants.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import ImageBox from '../../components/Common/Claim/ImageBox.jsx';

const BillingDialog = () => {
  const reportRef = useRef();
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfo.contents.roleCode === 'D_ADM';
  const aid = myInfo.contents.associationInfo?.associationId;
  const history = useHistory();
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  const [loading, setLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    carNbr: '',
    carVIN: '', // 차대번호
    newCarNbr: '', // 변경된 차량번호
    customerName: '', // 소유자
    customerContact: '', // 연락처
    association: { value: '' },
    warranty: { value: '' },
    receiptDate: null, // 접수일자
    addType: { value: '' },
    note: '', //  증상 입력
    vehicleValue: '', // 차량가액
    checkDate: '', // 점검일자
    checkDistance: '', // 성능 킬로수
    distanceDriven: '', // 접수 킬로수
    buyDate: '', // 인도일자
    elapsedDays: '', // 경과일수
  });
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [docs, setDocs] = useState([]); // claimDocs
  const [carDetailData, setCarDetailData] = useState(null); // 자동자 상세정보
  const [warrantyData, setWarrantyData] = useState({
    buyDate: null,
    checkDistance: 0,
    distanceDriven: 0,
    diagnosisType: [],
    reservationDate: '',
    producingCountry: 'D',
    carName: '',
    partnerInfo: [
      {
        partnerName: '',
        partnerType: '', // 진단점 P_D, 수리점 P_R, 둘다 P_DR
        claimStatusCode: '',
        receiptDate: '',
        dueDate: '',
        paymentAmt: 0,
        carNbr: '',
        statementNumber: '',
        checkDate: '',
        partnerId: 0,
        repairCode: '',
        reservationDate: '',
        documentGroupId: '',
        useYn: '',
        fileData: [],
      },
    ],
    radius: 0,
    partnerNote: '', // opt
  });
  const [statusCode, setStatusCode] = useState('Z01');
  const [toggleStates, setToggleStates] = useState([
    { idx: 3, status: 'Z03', value: false }, // 서류완료
    { idx: 4, status: 'Z04', value: false }, // 거절완료
    { idx: 5, status: 'Z05', value: false }, // 입고안내 완료
    { idx: 6, status: 'Z06', value: false }, // 입고점검 완료 - 진단점앱에서 사진 올라온 경우
    { idx: 7, status: 'Z07', value: false }, // 수리중 - 승인견적서, 승인 보내기 완료 시
    { idx: 8, status: 'Z08', value: false }, // 수리완료 - 진단점앱에서 수리사진 올라온 경우
  ]);

  const [repairData, setRepairData] = useState({
    date: '',
    maintenanceCode: '',
    workYN: '',
    workFee: null, // int
    repairNote: '',
  }); // 수리정보

  const [insuranceInfo, setInsuranceInfo] = useState({
    payDate: '',
    paymentAmt: null, // int
    deductibleAmt: null, // int
    completeDate: '',
    insurancePolicyNumber: '', // 증권번호
    code: '10',
  });

  const [newStatusCode, setNewStatusCode] = useState(null);
  const { id } = useParams();
  const [userNames, setUserNames] = useState({
    firstId: '',
    firstName: '',
    lastId: '',
    lastName: '',
  });

  // 경과일 수 계산 코드
  function calculateElapsedDays(targetDateString) {
    const today = dayjs();
    const targetDate = dayjs(targetDateString);
    const elapsedDays = today.diff(targetDate, 'day');
    return elapsedDays;
  }

  useEffect(() => {
    if (!!newStatusCode) {
      registerClaim('수정');
    }
  }, [newStatusCode]);

  useEffect(() => {
    if (statusCode) {
      const index = Number(statusCode.slice(1, 3));
      setToggleStates((prevStates) =>
        prevStates.map((state) => {
          if (state.idx <= index) {
            return { ...state, value: true };
          } else {
            return { ...state, value: false };
          }
        })
      );
    }
  }, [statusCode]);

  const [statusList, setStatusList] = useState([]);
  const getStatusList = async () => {
    try {
      const data = await Claim.getDocsType('Z');
      setStatusList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [insuranceList, setInsuranceList] = useState([]);
  const getInsuranceList = async () => {
    try {
      const data = await Claim.getInsurances();
      const list = data.filter(
        (el) => el.label?.includes('KB') || el.label?.includes('DB')
      );
      setInsuranceList(list);
    } catch (error) {
      console.log(error);
    }
  };

  function findLabelByCode(data, code) {
    const result = data.find((item) => item.codeData.insuranceCode === code);
    return result ? result.label : null;
  }

  useEffect(() => {
    getInsuranceList();
    getStatusList();
  }, []);

  useEffect(() => {
    if (history.location.state) {
      setCustomerInfo(history.location.state.customerInfo);
    }
  }, [history.location.state]);

  // console.log(detailData);
  const getDetailData = async (dataId) => {
    setLoading(true);
    try {
      const data = await Claim.getDetail(dataId);
      if (data) {
        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setCustomerInfo({
          carName: data.claimData?.carName ?? '',
          newCarNbr: data.claimData?.newCarNbr ?? '',
          customerName: data.claimData?.customerName ?? '',
          customerContact: data.claimData?.customerContact ?? '',
          carVIN: data.claimData?.vin ?? '',
          carNbr: data.claimData?.carNbr ?? '',
          association: {
            value: !!data.claimData?.checkFlag
              ? data.claimData.checkFlag === 'Y'
                ? 'association'
                : 'non_association'
              : null,
          },
          warranty: {
            value: !!data.claimData?.assuranceType
              ? data.claimData.assuranceType === '2'
                ? 'warranty'
                : 'warranty_s'
              : null,
          },
          receiptDate: data.claimData?.receiptDate
            ? CommonUtil.Formatter.stringToDayForm(data.claimData.receiptDate)
            : today,
          addType: {
            value: data.claimData?.addType ?? '',
          },
          note: data.claimData?.note ?? '',
          vehicleValue: formatNumber(data.claimData?.vehicleValue) ?? '',
          checkData: data.claimData?.checkData ?? null,
          checkDistance: data.claimData?.checkDistance ?? null,
          distanceDriven: data.claimData?.distanceDriven ?? null,
          buyDate: data.claimData?.buyDate
            ? CommonUtil.Formatter.stringToDayForm(data.claimData.buyDate)
            : null,
          elapsedDays: data.claimData?.buyDate
            ? calculateElapsedDays(data.claimData.buyDate)
            : 0,
        });
        setWarrantyData({
          buyDate: !!data.claimData.buyDate ? data.claimData.buyDate : null,
          checkDistance: data.claimData.checkDistance,
          distanceDriven: data.claimData.distanceDriven,
          diagnosisType: data.claimData.diagnosisType
            ? data.claimData.diagnosisType
            : null,
          reservationDate: data.claimData.reservationDate,
          producingCountry: data.claimData.producingCountry,
          carName: data.claimData.carName,
          partnerInfo: data.claimData.partnerInfo
            ? data.claimData.partnerInfo
            : null,
          partnerNote: data.claimData.partnerNote,
        });
        setStatusCode(
          data.claimData.claimStatus === 'Z1'
            ? 'Z01'
            : data.claimData.claimStatus
        );
        setRepairData({
          date: data.claimData.partnerReceiptDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.partnerReceiptDate
              )
            : '',
          maintenanceCode: data.claimData.maintenanceCode
            ? data.claimData.maintenanceCode.split(',')
            : '',
          workYN: data.claimData.workYN,
          workFee: data.claimData.workFee,
          repairNote: data.claimData.repairNote,
          repairOpinion: data.claimData.repairOpinion
            ? data.claimData.repairOpinion
            : '',
        });

        setInsuranceInfo({
          payDate: data.claimData.insurancePurchaseDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.insurancePurchaseDate
              )
            : null,
          paymentAmt: data.claimData.insurancePaymentAmt, // int
          deductibleAmt: data.claimData.deductibleAmt, // int
          completeDate: data.claimData.completeDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.completeDate)
            : null,
          insurancePolicyNumber: data.claimData.insurancePolicyNumber
            ? data.claimData.insurancePolicyNumber
            : '',
          code: data.claimData.insuranceCode
            ? data.claimData.insuranceCode
            : insuranceInfo.code,
        });
      }
      if (myInfo.contents) {
        setUserNames({
          firstId: data.claimData.receiptId ?? null,
          firstName: data.claimData.receiptName
            ? data.claimData.receiptName
            : null,
          lastId: myInfo.contents.userId,
          lastName: myInfo.contents.userName,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (myInfo.contents) {
      setUserNames({
        ...userNames,
        lastId: myInfo.contents.userId,
        lastName: myInfo.contents.userName,
      });
    }
  }, [myInfo.contents]);

  //ANCHOR -  클레임 POST, PUT
  const registerClaim = async (type) => {
    setLoading(true);
    let fileDataList = [];
    let filesList = [];
    let partnerFiles = [];

    const Data = {
      dataId: detailData?.dataId,
      shopId: detailData.shopId,
      associationId: aid,
      addType: detailData.addType,
      funnelsType: detailData.funnelsType, // W or A
      claimStatus: statusCode,
      customerName: customerInfo?.customerName,
      customerContact: customerInfo?.customerContact.replace(/[^0-9]/g, ''),
      checkFlag: customerInfo?.association.value === 'association' ? 'Y' : 'N',
      assuranceType: customerInfo?.warranty.value === 'warranty' ? '2' : '1',
      statementNumber: !!carDetailData ? carDetailData?.statementNumber : null, // opt
      carNbr: customerInfo.carNbr ?? null,
      vin: !!customerInfo?.carVIN ? customerInfo?.carVIN : null,
      newCarNbr: customerInfo.newCarNbr
        ? customerInfo.newCarNbr
        : detailData.newCarNbr
        ? detailData.newCarNbr
        : null,
      vehicleValue: !!customerInfo.vehicleValue
        ? Number(customerInfo.vehicleValue)
        : null,
      checkDate: !!carDetailData ? carDetailData?.checkDate : null, // opt
      buyDate:
        warrantyData.buyDate && typeof warrantyData.buyDate !== 'string'
          ? CommonUtil.Formatter.dateToString(warrantyData.buyDate)
          : !!warrantyData.buyDate
          ? warrantyData.buyDate
          : null,
      checkDistance: warrantyData.checkDistance
        ? Number(warrantyData.checkDistance)
        : null, //vehicleInformation > TRVL_DSTNC
      distanceDriven: warrantyData.distanceDriven
        ? Number(warrantyData.distanceDriven)
        : null,
      producingCountry: warrantyData.producingCountry
        ? warrantyData.producingCountry
        : null, // D or F // opt
      carName: warrantyData.carName ? warrantyData.carName : null,
      carAddress: detailData.carAddress,
      carLocation: detailData.carLocation, //Opt
      diagnosisType:
        warrantyData.diagnosisType &&
        typeof warrantyData.diagnosisType === 'string'
          ? warrantyData.diagnosisType
          : warrantyData.diagnosisType && !!warrantyData.diagnosisType.length
          ? warrantyData.diagnosisType.join()
          : null, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId
        ? detailData?.consultationGroupId
        : null,
      partnerInfo: null, //Opt 진단점 목록
      receiptId: userNames.firstId ? userNames.firstId : myInfo.contents.userId,
      receiptName: userNames.firstName
        ? userNames.firstName
        : myInfo.contents.userName,
      receiptDate: customerInfo?.receiptDate
        ? dayjs(customerInfo.receiptDate).format('YYYYMMDD')
        : dayjs(today).format('YYYYMMDD'),
      documentGroupId: type === '저장' ? null : detailData?.documentGroupId, //Opt
      note: customerInfo.note ?? null, //Opt
      partenerReceiptDate: detailData?.partenerReceiptDate ?? null, //Opt 수리 입고일자
      maintenanceCode: !!repairData.maintenanceCode.length
        ? repairData.maintenanceCode.join()
        : null,
      workYN: repairData.workYN ? repairData.workYN : null, //Opt 수리여부
      workFee: repairData.workFee ? Number(repairData.workFee) : null, //Opt 협의비용
      insuranceCode: insuranceInfo.code, // opt 보험코드
      insurancePolicyNumber: insuranceInfo.insurancePolicyNumber ?? null, //opt 증권번호
      insurancePurchaseDate: insuranceInfo.insurancePolicyNumber ?? null, //Opt 보험료 지급일
      insurancePaymentAmt: insuranceInfo.paymentAmt ?? null, //Opt 보험료
      deductibleAmt: insuranceInfo.deductibleAmt ?? null, // opt 자기부담금
      partnerNote: warrantyData.partnerNote ? warrantyData.partnerNote : null, //Opt
      repairNote: repairData.repairNote ? repairData.repairNote : null,
      repairOpinion: repairData.repairOpinion ? repairData.repairOpinion : null, // Opt 소견내용
      completeYN: insuranceInfo.completeDate ? 'Y' : 'N', //Opt 완료여부
      completeDate: insuranceInfo.completeDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.completeDate)
        : null, //Opt 완료일
      completeLoginId: myInfo.contents.loginId, //Opt 로그인유저 아이디
      completeName: myInfo.contents.userName, //Opt 로그인유저 이름
      completeLoginContact: myInfo.contents.contact, //opt 로그인유저 연락처
      fileData: fileDataList, //Otp
    };

    try {
      const data = await Claim.updateClaim(Data, filesList, partnerFiles);
      if (data) {
        window.cerp.toast.success('저장이 완료 되었습니다.');
        getDetailData(data.dataId);
        sessionStorage.removeItem('images');
        setLoading(false);
        setNewStatusCode(null);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(
        '저장 실패',
        '페이지 새로고침 후 다시 시도해주세요.'
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!id) {
      getDetailData(id);
    }
  }, [id]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('ko-KR').format(number);
  };

  const saveFilesAsZip = async () => {
    window.cerp.toast.info('다운로드를 시작합니다.');

    const partnerFiles = [];
    warrantyData.partnerInfo.map((partner) =>
      partner.partnerDocuments.map((doc) => partnerFiles.push(doc))
    );

    const folderName = customerInfo.carNbr;
    const files = docs.concat(partnerFiles);
    const haveIds = [];
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);

    const reportPDF = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    const papers = reportRef.current.querySelectorAll('.paper');
    const pdfWidth = 297;
    const pdfHeight = 210;

    if (papers.length > 0) {
      const now = dayjs().format('YYYY-MM-DD HH:mm:ss');

      for (let i = 0; i < papers.length; i++) {
        const page = papers[i];

        const canvas = await html2canvas(page, {
          scale: 2,
          logging: true,
          allowTaint: true,
          useCORS: true,
          imageTimeout: 0,
          backgroundColor: 'transparent',
        });

        const pageImage = canvas.toDataURL('image/png');
        const imgWidth = 297 - 20;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const x = (pdfWidth - imgWidth) / 2; // 가로 중앙 정렬

        reportPDF.addImage(pageImage, 'PNG', x, 0, imgWidth, imgHeight);
        if (i < papers.length - 1) {
          reportPDF.addPage();
        }
      }
    }

    const pdfBlob = reportPDF.output('blob');
    imagesFolder.file(`차량 기본 정보, 마감 정보.pdf`, pdfBlob);

    const imagePromises = files
      .filter((file) => file.documentCode !== 'C_FRONT')
      .map(async (file, index) => {
        let docType = DOC_TYPE.find((doc) => doc.value === file.documentCode);
        if (file.documentCode === 'C_ORIGINALDOCS') {
          docType = {
            ...docType,
            category: '매매상사접수서류',
            label: '자동차등록원부(갑)',
          };
        }
        const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(
          file.originalFileName
        );
        const fileExtension = isImage
          ? '.jpg'
          : file.originalFileName.slice(file.originalFileName.lastIndexOf('.'));
        const fileName = docType
          ? `${docType.category}/${index + 1}_${docType.label}.${fileExtension}`
          : `${index + 1}_${file.documentCode}.${fileExtension}`;
        const imgBlob = await Claim.getBlobFromUrl(file.filePath);
        imagesFolder.file(fileName, imgBlob, { binary: true });
      });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.success('다운로드가 완료되었습니다.');
  };

  const handlePrint = useReactToPrint({
    content: () => {
      const papers = reportRef.current.querySelectorAll('.paper');
      if (papers.length === 0) return false;

      return reportRef.current;
    },
    onBeforePrint: async () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  return (
    <div ref={reportRef}>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      ></BlockUI>
      <div className="px-3 py-2 fixed surface-200 w-full z-5 border-bottom-1 border-400">
        <div className="flex md:justify-content-between justify-content-start">
          <div className="flex align-items-center gap-2 ">
            <div className="font-bold ml-2 text-xl md:block hidden">
              성능 보험 지급 관리
            </div>
          </div>
          <div className="flex gap-4">
            {partner ? null : (
              <div className="flex gap-2">
                <div className="flex align-items-center">*업무 진행상태 :</div>
                <Dropdown
                  value={statusCode}
                  onChange={(e) => {
                    setStatusCode(e.target.value);
                  }}
                  options={statusList}
                  optionLabel="label"
                  placeholder="상태 선택"
                  className="w-full md:w-16rem font-bold overflow-hidden"
                />
              </div>
            )}
            <Button.Default
              className="sm:text-lg sm:text-base"
              icon="pi pi-save"
              label={'저장'}
              onClick={() => registerClaim('수정')}
            />
            <Button.Default
              className="sm:text-lg sm:text-base p-button-outlined"
              icon="pi pi-print"
              label={'이미지 전체 다운로드'}
              onClick={() => saveFilesAsZip()}
            />
            <Button.Default
              className="p-button-outlined p-button-danger"
              icon="pi pi-times"
              label=""
              onClick={() => {
                sessionStorage.removeItem('images');
                sessionStorage.removeItem('data');
                history.push('/compensation/billing/list');
              }}
            />
          </div>
        </div>
      </div>
      <div id="performance-check-report" className="claimPaper">
        <div className="inner">
          {/* 기본 정보 테이블 */}
          <h3 className="font-bold text-center pt-6">
            에스알 성능 보험 지급 관리
          </h3>
          <div className="paper flex flex-wrap justify-content-center gap-2 pb-3">
            <table className="bg-white">
              <thead>
                <tr>
                  <th colSpan="6" className="header">
                    차량 기본 정보
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="subject">차명</td>
                  <td className="content">{customerInfo?.carName || '-'}</td>
                  <td className="subject">차량번호</td>
                  <td className="content">{customerInfo?.carNbr || '-'}</td>
                  <td className="subject">변경된 차량번호</td>
                  <td className="content">{customerInfo?.newCarNbr || '-'}</td>
                </tr>
                <tr>
                  <td className="subject">차대번호</td>
                  <td colSpan="5" className="merged">
                    {customerInfo?.carVIN || '-'}
                  </td>
                </tr>
                <tr>
                  <td className="subject">소유자</td>
                  <td className="content">
                    {customerInfo?.customerName || '-'}
                  </td>
                  <td className="subject">연락처</td>
                  <td className="content">
                    {customerInfo?.customerContact != null
                      ? CommonUtil.Formatter.phone(
                          customerInfo?.customerContact
                        )
                      : '-'}
                  </td>
                  <td className="subject">접수일자</td>
                  <td className="content">
                    {customerInfo?.receiptDate || '-'}
                  </td>
                </tr>
                <tr>
                  <td className="subject">차량 인도일 (경과일수)</td>
                  <td className="content">
                    {customerInfo?.buyDate != null
                      ? `${customerInfo?.buyDate} (${customerInfo?.elapsedDays}일 경과)`
                      : '-'}
                  </td>
                  <td className="subject">성능 킬로수</td>
                  <td className="content">
                    {customerInfo?.checkDistance != null
                      ? `${formatNumber(customerInfo?.checkDistance)}Km`
                      : '-'}
                  </td>
                  <td className="subject">접수 킬로수</td>
                  <td className="content">
                    {customerInfo?.distanceDriven != null
                      ? `${formatNumber(customerInfo?.distanceDriven)}Km`
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="subject">성능점검일</td>
                  <td className="content">{customerInfo?.checkDate || '-'}</td>
                  <td className="subject">차량가액</td>
                  <td colSpan="3" className="content">
                    {customerInfo?.vehicleValue != null
                      ? `${customerInfo?.vehicleValue}원`
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="subject">증상</td>
                  <td colSpan="5" className="merged">
                    {customerInfo?.note}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="bg-white">
              <thead>
                <tr>
                  <th colSpan="6" className="header">
                    마감정보
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="subject">청구일</td>
                  <td colSpan="5" className="content">
                    <div className=" grid lg:col-4 col align-items-center">
                      <div className="w-18rem">
                        <Calendar
                          value={insuranceInfo.completeDate}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              completeDate: e.target.value,
                            })
                          }
                          dateFormat="yy-mm-dd"
                          showButtonBar
                          showIcon
                          mask="9999-99-99"
                          placeholder="청구일 선택"
                          className="w-18rem"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="subject">보험사</td>
                  <td className="content">
                    {findLabelByCode(insuranceList, insuranceInfo.code)}
                  </td>
                  <td colSpan="2" className="subject">
                    증권번호
                  </td>
                  <td colSpan="2" className="content">
                    {insuranceInfo.insurancePolicyNumber || '-'}
                  </td>
                </tr>
                <tr>
                  <td className="subject">총 지급액</td>
                  <td colSpan="4" className="content">
                    {!!insuranceInfo.paymentAmt
                      ? `${formatNumber(insuranceInfo.paymentAmt)}원`
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Divider />
          <div className="section_title bg-blue-100 border-blue-300">
            <p>차량 기본 사진(고객, 딜러)</p>
          </div>
          <div className="imageWrap">
            <ImageBox title={'성능기록부'} code={'S_CHECK1'} docs={docs} />
            <ImageBox
              title={'매매계약서 (업소보관용)'}
              code={'S_CONTRACT'}
              docs={docs}
            />
            <ImageBox
              title={'자동차등록증'}
              code={'C_CERTIFICATE'}
              docs={docs}
            />
            <ImageBox
              title={'자동차등록원부(갑)'}
              code={'S_CHECK2'}
              docs={docs}
            />
            <ImageBox title={'리콜'} code={'C_RECALL'} docs={docs} />
            <ImageBox
              title={'현재 기준 계기판 사진'}
              code={'C_DASHBOARD'}
              docs={docs}
            />
            <ImageBox title={'기타사진'} code={'C_ETC'} docs={docs} />
            <ImageBox
              title={'개인정보 동의서'}
              code={'C_CONSENT'}
              docs={docs}
            />
          </div>
          {warrantyData?.partnerInfo.map((partnerData) => {
            return (
              <div key={partnerData.partnerId}>
                <div className="section_title relative flex align-contents-center justify-content-center bg-blue-100 border-blue-100 border-blue-300">
                  <p className="m-0">
                    [{partnerData.partnerName}] 입고점검사진
                  </p>
                </div>
                <div className="imageWrap">
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'소견서'}
                    code={'P_OPINION'}
                  />
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'입고 차량전면사진'}
                    code={'P_FRONT'}
                  />
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'입고 계기판사진'}
                    code={'P_DASHBOARD'}
                  />
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'점검사진'}
                    code={'P_CHECK'}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="">
          <div className="claim claim_paper_wrapper">
            {warrantyData?.partnerInfo.map((partnerData) => {
              return (
                <div className="inner" key={partnerData?.partnerId}>
                  <div className="section_title bg-blue-100 border-blue-100 border-blue-300">
                    <p>[{partnerData.partnerName}] 수리 완료 사진</p>
                  </div>
                  <div className="imageWrap">
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      repairCenter={true}
                      title={'보험금청구동의서'}
                      code={'P_INSURANCE_TEMPLATE'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      repairCenter={true}
                      title={'승인견적서'}
                      code={'P_APPROVED'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      repairCenter={true}
                      title={'사업자등록증'}
                      code={'P_REGISTRATION'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      repairCenter={true}
                      title={'사업자통장사본'}
                      code={'P_BANKBOOK'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      repairCenter={true}
                      title={'기타'}
                      code={'P_ETC'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      repairCenter={true}
                      title={'정비사진'}
                      code={'P_MAINTENANCE'}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDialog;
