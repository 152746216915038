import axios from 'axios';
import AxiosUtil from '../utils/axiosUtil';

const BASE_URI = '/apis/settlement/safe6';

export const Settlement = {
  /**
   * 매매상사별 월정산내역
   * @param {object} data
   * @returns
   */
  getCompanies: async (data) => {
    const res = await axios.get(`${BASE_URI}/companies`, { params: data });
    return res.data.data;
  },

  /**
   * 매매상사 각 월정산 세부내역
   * @param {object} data
   * @returns
   */
  getMonthCustomerCompany: async (data) => {
    const { settlementMonth, customerCompanyId, page, size, checkType } = data;
    const res = await axios.get(`${BASE_URI}/companies/list`, {
      params: {
        settlementMonth,
        customerCompanyId,
        checkType,
        page: page,
        size: size,
      },
    });
    return res.data.data;
  },

  /**
   * 매매상사별 일별 정산내역
   * @param {object} data
   * @returns
   */
  getCompany: async (data) => {
    const res = await axios.get(`${BASE_URI}/company`, { params: data });
    return res.data.data;
  },

  getCompanyDetail: async (dataId) => {
    const res = await axios.get(`${BASE_URI}/company/${dataId}`);
    return res.data.data;
  },
  /**
   * @param {*} data settleMonth, requestType, page, size
   * @description 마스터 계정 매도차량 변경내역 조회
   */
  getChangeList: async (data) => {
    const res = await axios.get(`${BASE_URI}/data`, { params: data });
    return res.data.data;
  },
  /**
   * @param {*} data dataId, requestType
   * @description 변경요청 승인/거절
   */
  approveChange: async (data) => {
    const res = await axios.put(`${BASE_URI}/data`, null, { params: data });
    return res.data.data;
  },
  /**
   * safe6 비대상 차량 증빙자료 등록
   */
  registerPhoto: async (data, files) => {
    const res = await AxiosUtil.Async.updateMultipart(
      `${BASE_URI}/photo`,
      data,
      files
    );
    return res.data;
  },

  request: async (data) => {
    const { carNbr, distanceDriven } = data;
    const res = await axios.get(
      `${BASE_URI}/request/${carNbr}/${distanceDriven}`
    );
    return res.data.data;
  },

  /**
   * 매매상사 각 일정산 세부내역
   * @param {object} data
   * @returns
   */
  getCustomerCompany: async (data) => {
    const { settlementMonth, page, size, checkType, dealerName, carNo } = data;
    const res = await axios.get(`${BASE_URI}/company/list/${settlementMonth}`, {
      params: {
        checkType: checkType,
        dealerName: dealerName,
        carNo: carNo,
        page: page,
        size: size,
      },
    });
    return res.data.data;
  },

  uploadExcel: async (data, files) => {
    if (!files.length) return console.log('파일 없음');
    const formData = new FormData();

    if (data) formData.append('settleMonth', data);

    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append('file', file);
      });
    }

    // try {
    const res = await axios.post(`${BASE_URI}/excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
    // } catch (e) {
    // return e;
    // }
  },
  /**
   * 매매상사 어카운트 조회
   */
  getCustomerAccount: async () => {
    const res = await axios.get(`${BASE_URI}/company/account`);
    return res.data.data;
  },
};

export default Settlement;
