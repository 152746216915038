import * as Button from './../../components/Atoms/Button';
import * as Modal from './../../components/Atoms/Modal';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { compressImages } from '../../utils/compressUtil';
import FileState from '../../enums/FileState';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Claim from '../../services/ClaimService';
import { ToggleButton } from 'primereact/togglebutton';
import DocsViewer from '../Common/Claim/DocsViewer';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Partner } from '../../services/PartnerService';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const PartnerDocument = ({
  fileItems,
  deleteImgs,
  setFileItems,
  setDeleteImgs,
  copyFile,
  carNbr,
  checked,
  onToggle,
  selectedPartner,
  setSelectedPartner,
  setShowSmsType,
  setShowSmsModal,
  setShowPushModal,
  setSmsData,
  smsData,
  setShowPushType,
  setPartnerInfo,
  isApprovalDetail = false,
  forPartner = false, // '/upload/claim'에서 사용하는 경우 true
  setPartnerImgConfirm,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [curTitle, setCurTitle] = useState('');
  const [loading, setLoading] = useState(false);
  let { dealer, partner, aI, pId } = useParams();
  const dealerUser = dealer?.split('=')[1] === 'true' ? true : false;
  const partnerUser = partner?.split('=')[1] === 'true' ? true : false;
  const aId = aI?.split('=')[1];
  const partnerId = Number(pId?.split('=')[1]);
  const [curImg, setCurImg] = useState('');
  const [curPartner, setCurPartner] = useState([]);
  const [curPartnerId, setCurParnterId] = useState('');
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const [partnerLoginUser, setPartnerLoginUser] = useState(false);
  const [isApproval, setIsApproval] = useState(false); // 승인견적서 이미지 0장 이상 여부
  const [isTemplate, setIsTemplate] = useState(false); // 보험청구동의서 이미지 0장 이상 여부
  const [isZ06, setIsZ06] = useState(false); // 승인견적서,보험청구동의서 둘 다 true인지 여부
  const location = useLocation();
  const accessToken = localStorage.getItem('accessToken');
  const history = useHistory();

  // 사진 업로드 페이지에서 토큰이 없다면 로그인 페이지로 이동
  useEffect(() => {
    if (!accessToken && location.pathname.startsWith('/upload/claim')) {
      history.push('/login');
    }
  }, [location]);

  useEffect(() => {
    if (!!isApproval && !!isTemplate) {
      setIsZ06(true);
    } else {
      setIsZ06(false);
    }
  }, [isApproval, isTemplate]);

  useEffect(() => {
    if (myInfo.state === 'hasValue') {
      if (myInfo.contents.roleCode === 'D_ADM') {
        setPartnerLoginUser(true);
      } else {
        setPartnerLoginUser(false);
      }
    }
  }, [myInfo.state]);

  useEffect(() => {
    if (partnerId && !!selectedPartner.length) {
      setCurPartner([selectedPartner.find((el) => el.partnerId === partnerId)]);
    }
  }, [selectedPartner, partnerId]);

  useEffect(() => {
    let files = [];
    if (!!curPartner && partnerId) {
      curPartner.map((el) => el.partnerDocuments.map((doc) => files.push(doc)));
      if (forPartner) {
        const updatedDocs = files.map((item) => ({
          ...item,
          state: 'L',
        }));
        return setFileItems(updatedDocs);
      }
      setFileItems(files);
    }
  }, [curPartner, partnerId]);
  const filteredSelectPartner = selectedPartner.filter(
    (partner) => partner.useYn === 'Y'
  );

  useEffect(() => {
    if (!!curPartnerId) {
      getPartnerInfo(curPartnerId);
    }
  }, [curPartnerId]);

  const getPartnerInfo = async (id) => {
    try {
      const data = await Partner.getDetailData(id);
      if (data) {
        setPartnerInfo(data?.partnerInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const P_CHECK = useRef(null);
  const P_FRONT = useRef(null);
  const P_DASHBOARD = useRef(null);
  const P_LIST = useRef(null);
  const P_OPINION = useRef(null);
  const P_PART = useRef(null);
  const P_MAINTENANCE = useRef(null);
  const P_REGISTRATION = useRef(null);
  const P_BANKBOOK = useRef(null);
  const P_COMPLETED = useRef(null);
  const P_INSURANCE = useRef(null);
  const P_ETC = useRef(null);
  const P_APPROVED = useRef(null);

  const deleteFile = (e) => {
    const imgId = e.target.alt ? Number(e.target.alt) : Number(e.target.id);
    const partner = selectedPartner.find((el) => el.partnerId === curPartnerId);
    const imgFiles = partner.partnerDocuments;
    if (!!imgId) {
      const trashFile = imgFiles.filter((el) => el.imgId === imgId);
      const deletedList = imgFiles.filter((el) => el.imgId !== imgId);
      trashFile.map((el) =>
        deletedList.push({
          imgId: el.imgId,
          documentCode: el.documentCode,
          state: 'D',
        })
      );
      setDeleteImgs((ps) => [...ps, ...trashFile]);
      return (partner.partnerDocuments = deletedList);
    } else {
      const name = e.target.alt ? e.target.alt : e.target.id;
      const filteredFile = imgFiles.filter(
        (el) => !(!el?.imgId && el.file.name === name)
      );
      const deletedList = fileItems.filter((el) => el?.name !== name);
      setFileItems(deletedList);
      return (partner.partnerDocuments = filteredFile);
    }
  };

  const filterDocuments = (partnerDocuments, documentCode) => {
    return partnerDocuments?.filter((doc) => doc.documentCode === documentCode);
  };

  const [downloadLoading, setDownloadLoading] = useState(false);
  const saveFilesAsZip = async (el, type) => {
    setDownloadLoading(true);
    const partnerName = el?.partnerName;
    const checkImg = filterDocuments(el?.partnerDocuments, 'P_CHECK');
    const frontImg = filterDocuments(el?.partnerDocuments, 'P_FRONT');
    const dashImg = filterDocuments(el?.partnerDocuments, 'P_DASHBOARD');
    const listImg = filterDocuments(el?.partnerDocuments, 'P_LIST');
    const optionImg = filterDocuments(el?.partnerDocuments, 'P_OPINION');
    const partImg = filterDocuments(el?.partnerDocuments, 'P_PART');
    const maintenanceImg = filterDocuments(
      el?.partnerDocuments,
      'P_MAINTENANCE'
    );
    const registerImg = filterDocuments(el?.partnerDocuments, 'P_REGISTRATION');
    const bankImg = filterDocuments(el?.partnerDocuments, 'P_BANKBOOK');
    const completeImg = filterDocuments(el?.partnerDocuments, 'P_COMPLETED');
    const insuranceImg = filterDocuments(el?.partnerDocuments, 'P_INSURANCE');
    const insuranceTemplateImg = filterDocuments(
      el?.partnerDocuments,
      'P_INSURANCE_TEMPLATE'
    );
    const etcImg = filterDocuments(el?.partnerDocuments, 'P_ETC');
    const approvedImg = filterDocuments(el?.partnerDocuments, 'P_APPROVED');
    const Dfiles = [
      ...checkImg,
      ...frontImg,
      ...dashImg,
      ...listImg,
      ...optionImg,
    ];
    const Rfiles = [
      ...maintenanceImg,
      ...partImg,
      ...registerImg,
      ...bankImg,
      ...completeImg,
      ...insuranceImg,
      ...insuranceTemplateImg,
      ...etcImg,
      ...approvedImg,
    ];

    const files = type === '진단' ? Dfiles : Rfiles;

    const folderName = `${partnerName}_${carNbr}_${type}서류`;
    const haveIds = [];
    const findFile = files.find((el) => el.state === 'I');
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));

    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length || !!findFile)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const imagePromises = files.map(async (file, index) => {
      const imgBlob = await Claim.getBlobFromUrl(file.filePath);
      const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(file.originalFileName);
      const fileName = isImage
        ? `${file.originalFileName.split('.')[0]}_${index + 1}.jpg`
        : file.originalFileName;
      imagesFolder.file(fileName, imgBlob, {
        binary: true,
      });
    });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    setDownloadLoading(false);
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  const elementRefs = {
    P_CHECK,
    P_FRONT,
    P_DASHBOARD,
    P_OPINION,
    P_LIST,
    P_PART,
    P_MAINTENANCE,
    P_REGISTRATION,
    P_BANKBOOK,
    P_INSURANCE,
    P_COMPLETED,
    P_ETC,
    P_APPROVED,
  };

  const handleClick = (e) => {
    const docType = DOC_TYPE.find((doc) => doc.label === e.target.id);
    if (docType && elementRefs[docType.value]) {
      elementRefs[docType.value].current.click();
    }
  };

  const uploadFiles = async (acceptedFiles, title, partnerId) => {
    setLoading(true);
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    const fileList = Object.values(acceptedFiles);
    fileList.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );

    const compressedImages = await compressImages(fileList, options);
    compressedImages.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });

    const code = DOC_TYPE.find((el) => el.label === title).value;
    const partCodeAttachedFiles = _.reduce(
      compressedImages,
      (res, v) => {
        res.push({
          documentCode: code,
          file: v,
          state: FileState.Inserted,
        });
        return res;
      },
      []
    );

    const { fileData, files } = CommonUtil.File.convertForServerClaimPartner(
      partCodeAttachedFiles
    );

    const partner = filteredSelectPartner.find(
      (el) => el.partnerId === partnerId
    );

    if (partner?.partnerDocuments) {
      partCodeAttachedFiles.map((el) => partner?.partnerDocuments.push(el));
    }
    if (forPartner) {
      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
    } else {
      setFileItems((ps) => [...ps, ...files]);
    }
    setLoading(false);
    window.cerp.toast.info(
      '업로드 성공',
      '저장 버튼을 클릭하여 저장해 주세요.'
    );
  };

  const [isComplete, setIsComplete] = useState(false);
  useEffect(() => {
    return filteredSelectPartner?.map((el, idx) => {
      const insurance = filterDocuments(
        el?.partnerDocuments,
        'P_INSURANCE_TEMPLATE'
      )?.some((el) => el.dataId);

      const approved = filterDocuments(
        el?.partnerDocuments,
        'P_APPROVED'
      )?.some((el) => el.dataId);
      return insurance && approved ? setIsComplete(true) : setIsComplete(false);
    });
  }, [filteredSelectPartner]);

  const hasAllRequiredDocuments = (documents) => {
    return REQUIRED_DOCUMENT_CODES?.every((code) =>
      documents?.some((doc) => doc.documentCode === code)
    );
  };

  const [imgs, setImgs] = useState([]);
  useEffect(() => {
    if (filteredSelectPartner && !!partnerLoginUser) {
      const filtered = filteredSelectPartner[0]?.partnerDocuments.filter(
        (el) => el.state !== 'D'
      );
      setImgs(filtered);
    }
  }, [filteredSelectPartner[0]?.partnerDocuments?.length, deleteImgs]);

  useEffect(() => {
    if (!!imgs.length) {
      const res = hasAllRequiredDocuments(imgs);
      setPartnerImgConfirm(res);
    }
  }, [deleteImgs, imgs]);

  return (
    <>
      <Panel
        header={
          <div className="flex justify-content-between align-items-center">
            <div>
              {isApprovalDetail ? '입고점검 및 수리' : '5. 입고점검 및 수리'}
            </div>
            <div className="flex gap-2">
              {!dealerUser && !partnerUser && !aId ? (
                <>
                  <Button.Default
                    label="리콜 여부 조회 바로가기"
                    icon="pi pi-external-link"
                    iconPos="right"
                    type={'button'}
                    onClick={(e) => {
                      e.preventDefault();
                      CommonUtil.Window.popup(
                        'https://www.car.go.kr/home/main.do',
                        '리콜 여부 조회',
                        500,
                        700
                      );
                    }}
                  />
                </>
              ) : !dealerUser || !partnerUser ? null : null}
            </div>
          </div>
        }
        className="pt-3 sm:text-base text-2xl"
      >
        <BlockUI
          template={
            loading &&
            dealerUser !== undefined && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="grid p-2">
            {!!filteredSelectPartner?.length ? (
              filteredSelectPartner?.map((el, idx) => {
                const checkImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_CHECK'
                );
                const frontImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_FRONT'
                );
                const dashImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_DASHBOARD'
                );
                const listImg = filterDocuments(el?.partnerDocuments, 'P_LIST');
                const optionImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_OPINION'
                );
                const partImg = filterDocuments(el?.partnerDocuments, 'P_PART');
                const maintenanceImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_MAINTENANCE'
                );
                const registerImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_REGISTRATION'
                );
                const bankImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_BANKBOOK'
                );
                const completeImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_COMPLETED'
                );
                const insuranceImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_INSURANCE'
                );
                const insuranceTemplateImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_INSURANCE_TEMPLATE'
                );
                const etcImg = filterDocuments(el?.partnerDocuments, 'P_ETC');
                const approvedImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_APPROVED'
                );

                // 성능 보험 승인 관리 (분기처리)
                if (isApprovalDetail) {
                  return (
                    <React.Fragment key={idx}>
                      <div
                        style={{
                          display: `${
                            partnerUser && partnerId !== el.partnerId
                              ? 'none'
                              : ''
                          }`,
                        }}
                        className={
                          'relative col-12 border-1 border-gray-300 border-round mb-2'
                        }
                        onClick={() =>
                          setCurParnterId(
                            partnerUser && !!partnerId
                              ? partnerId
                              : el.partnerId
                          )
                        }
                      >
                        {!partnerUser && !partnerLoginUser ? (
                          <div className="flex justify-content-between">
                            <div className="flex align-items-center font-bold text-primary p-2">
                              [{el?.partnerName}]
                            </div>
                            {/* <Button.Default
                              label="진단/수리사진 요청"
                              icon="pi pi-comment"
                              className="p-button-outlined m-2 apple"
                              onClick={() => {
                                setShowSmsModal(true);
                                setShowSmsType('PARTNER_UPLOAD_URL_FOR_APPLE');
                                setSmsData({
                                  ...smsData,
                                  partnerInfo: el,
                                  destAddr: el.cellphone,
                                });
                              }}
                              tooltip="수기/애플 사용자"
                            /> */}
                          </div>
                        ) : null}

                        {partnerId ? null : (
                          <div className="flex justify-content-between w-full flex-wrap">
                            <div className="px-2 w-full flex flex-column justify-content-between align-content-center">
                              <div className="flex flex-column align-content-start gap-2 pt-5">
                                <DocsViewer
                                  title="승인견적서"
                                  imgFiles={approvedImg}
                                  buttonName={'P_APPROVED' + el.partnerId}
                                  setCurTitle={setCurTitle}
                                  setShowModal={setShowModal}
                                  curImg={curImg}
                                  setCurImg={setCurImg}
                                  deleteFile={deleteFile}
                                  handleClick={handleClick}
                                  upload={true}
                                  setIsApproval={setIsApproval}
                                />
                                <DocsViewer
                                  title="보험금청구동의서(빈 양식)"
                                  imgFiles={insuranceTemplateImg}
                                  buttonName={
                                    'P_INSURANCE_TEMPLATE' + el.partnerId
                                  }
                                  setCurTitle={setCurTitle}
                                  setShowModal={setShowModal}
                                  curImg={curImg}
                                  setCurImg={setCurImg}
                                  deleteFile={deleteFile}
                                  handleClick={handleClick}
                                  upload={true}
                                  setIsTemplate={setIsTemplate}
                                />
                              </div>
                              <div className="absolute top-0 right-0 gap-2 p-3">
                                <Button.Default
                                  className="p-button-outlined mr-1"
                                  icon="pi pi-check-circle"
                                  label="서류반영"
                                  onClick={() => {
                                    if (
                                      !insuranceTemplateImg?.length ||
                                      !approvedImg?.length
                                    ) {
                                      window.cerp.toast.warn(
                                        '승인견적서, 보험금청구동의서 모두 업로드해주세요.'
                                      );
                                    } else if (isComplete || isZ06) {
                                      onToggle('Z17', true);
                                    } else {
                                      onToggle('Z07', false);
                                    }
                                  }}
                                />
                                <Button.Default
                                  label="수리승인 안내"
                                  icon="pi pi-comment"
                                  className="p-button-outlined"
                                  onClick={() => {
                                    if (
                                      !insuranceTemplateImg?.length ||
                                      !approvedImg?.length
                                    ) {
                                      window.cerp.toast.warn(
                                        '승인견적서, 보험금청구동의서 모두 업로드해주세요.'
                                      );
                                    } else {
                                      setShowSmsModal(true);
                                      setShowSmsType('PARTNER_UPLOAD_URL');
                                      setSmsData({
                                        ...smsData,
                                        partnerInfo: el,
                                        destAddr: el.cellphone,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <Divider type="dashed" />
                            <div className="p-2 w-full text-base flex align-items-center">
                              <div className="font-bold sm:text-base text-2xl mr-4 md:mr-5 lg:mr-8">
                                수리 여부
                              </div>
                              <RadioButton
                                inputId={'workY' + el.partnerId}
                                name="customerSearchBy"
                                value="I"
                                className="mr-2"
                                onChange={(e) => {
                                  const updatedSelectedPartner =
                                    filteredSelectPartner.map((item) => {
                                      if (item.partnerId === el.partnerId) {
                                        return {
                                          ...item,
                                          claimStatusCode: e.value,
                                        };
                                      }
                                      return item;
                                    });
                                  setSelectedPartner(updatedSelectedPartner);
                                }}
                                checked={el.claimStatusCode === 'I'}
                              />
                              <label htmlFor={'workY' + el.partnerId}>
                                수리 진행
                              </label>
                              <RadioButton
                                inputId={'workN' + el.partnerId}
                                name="customerSearchBy"
                                value="N"
                                className="ml-4 mr-2"
                                onChange={(e) => {
                                  const updatedSelectedPartner =
                                    filteredSelectPartner.map((item) => {
                                      if (item.partnerId === el.partnerId) {
                                        return {
                                          ...item,
                                          claimStatusCode: e.value,
                                        };
                                      }
                                      return item;
                                    });
                                  setSelectedPartner(updatedSelectedPartner);
                                }}
                                checked={el.claimStatusCode === 'N'}
                              />
                              <label htmlFor={'workN' + el.partnerId}>
                                미수리
                              </label>
                              <RadioButton
                                inputId={'workC' + el.partnerId}
                                name="customerSearchBy"
                                value="C"
                                className="ml-4 mr-2"
                                onChange={(e) => {
                                  const updatedSelectedPartner =
                                    filteredSelectPartner.map((item) => {
                                      if (item.partnerId === el.partnerId) {
                                        return {
                                          ...item,
                                          claimStatusCode: e.value,
                                        };
                                      }
                                      return item;
                                    });
                                  setSelectedPartner(updatedSelectedPartner);
                                }}
                                checked={el.claimStatusCode === 'C'}
                              />
                              <label htmlFor={'workC' + el.partnerId}>
                                당사면책
                              </label>
                            </div>
                            <Divider type="dashed" />
                          </div>
                        )}
                        <div className="flex align-items-center justify-content-between mt-1 mb-2">
                          <div className="flex align-items-center px-2 text-base">
                            <div className="flex align-items-center font-bold sm:text-base text-2xl mr-4 md:mr-5 lg:mr-8">
                              <label htmlFor="">지급금액</label>
                            </div>
                            <div className="">
                              <InputText
                                value={
                                  el?.paymentAmt
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    ?.toString() || ''
                                }
                                onChange={(e) => {
                                  const updatedSelectedPartner =
                                    filteredSelectPartner.map((item) => {
                                      if (item.partnerId === el.partnerId) {
                                        return {
                                          ...item,
                                          paymentAmt: e.target.value.replaceAll(
                                            ',',
                                            ''
                                          ),
                                        };
                                      }
                                      return item;
                                    });
                                  setSelectedPartner(updatedSelectedPartner);
                                }}
                                placeholder="지급금액 입력"
                                readOnly={partnerLoginUser}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }

                // 클레임 관리
                return (
                  <React.Fragment key={idx}>
                    <div
                      style={{
                        display: `${
                          partnerUser && partnerId !== el.partnerId
                            ? 'none'
                            : ''
                        }`,
                      }}
                      className={
                        'col-12 border-1 border-gray-300 border-round mb-2'
                      }
                      onClick={() =>
                        setCurParnterId(
                          partnerUser && !!partnerId ? partnerId : el.partnerId
                        )
                      }
                    >
                      {!partnerUser && !partnerLoginUser ? (
                        <div className="flex justify-content-between">
                          <div className="flex align-items-center font-bold text-primary p-2">
                            [{el?.partnerName}]
                          </div>
                          <Button.Default
                            label="진단/수리사진 요청"
                            icon="pi pi-comment"
                            className="p-button-outlined m-2 apple"
                            onClick={() => {
                              setShowSmsModal(true);
                              setShowSmsType('PARTNER_UPLOAD_URL_FOR_APPLE');
                              setSmsData({
                                ...smsData,
                                partnerInfo: el,
                                destAddr: el.cellphone,
                              });
                            }}
                            tooltip="수기/애플 사용자"
                          />
                        </div>
                      ) : null}
                      <div className="w-full flex justify-content-between align-items-center p-2 mb-2">
                        <Calendar
                          value={
                            el?.receiptDate &&
                            typeof el?.receiptDate === 'string'
                              ? CommonUtil.Formatter.stringToDate(
                                  el?.receiptDate
                                )
                              : el?.receiptDate || ''
                          }
                          onChange={(e) => {
                            const updatedSelectedPartner =
                              filteredSelectPartner.map((item) => {
                                if (item.partnerId === el.partnerId) {
                                  return {
                                    ...item,
                                    receiptDate: e.value,
                                  };
                                }
                                return item;
                              });
                            setSelectedPartner(updatedSelectedPartner);
                          }}
                          dateFormat="yy-mm-dd"
                          showButtonBar
                          showIcon
                          mask="9999-99-99"
                          placeholder="입고일자 선택"
                          id={el.partnerId}
                          name="receiptDate"
                        />
                        {!partnerUser && !partnerLoginUser ? (
                          <>
                            <div className="col px-2">
                              <div className="flex justify-content-end align-items-center">
                                <Button.Default
                                  label="미비서류 앱푸시"
                                  icon="pi pi-mobile"
                                  className="p-button-outlined mr-1"
                                  onClick={() => {
                                    setShowPushModal(true);
                                    setShowPushType('CENTER_DOCS');
                                  }}
                                />
                              </div>
                            </div>
                            <ToggleButton
                              checked={
                                checked?.find((el) => el.status === 'Z06')
                                  ?.value
                              }
                              onChange={(e) => onToggle('Z06', e.value)}
                              onLabel="완료"
                              offLabel="미확인"
                              onIcon="pi pi-flag-fill"
                              offIcon="pi pi-flag"
                            />
                          </>
                        ) : null}
                      </div>

                      <div className="field grid mb-0 p-2 align-items-center">
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="입고 차량전면사진"
                            imgFiles={frontImg}
                            buttonName={'P_FRONT' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_FRONT}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '입고 차량전면사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="입고 계기판사진"
                            imgFiles={dashImg}
                            buttonName={'P_DASHBOARD' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_DASHBOARD}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '입고 계기판사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="점검사진"
                            imgFiles={checkImg}
                            buttonName={'P_CHECK' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_CHECK}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '점검사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="부품명세서"
                            imgFiles={partImg}
                            buttonName={'P_PART' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_PART}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '부품명세서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="소견서"
                            imgFiles={optionImg}
                            buttonName={'P_OPINION' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_OPINION}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '소견서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="견적서"
                            imgFiles={listImg}
                            buttonName={'P_LIST' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_LIST}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '견적서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>{' '}
                      </div>

                      {(!dealerUser || !partnerUser) &&
                      aId &&
                      partnerId ? null : (
                        <div className="col px-2">
                          <div className="flex justify-content-end  align-items-center h-3rem">
                            <Button.Default
                              className="p-button-outlined"
                              icon="pi pi-download"
                              label="다운로드"
                              onClick={() => saveFilesAsZip(el, '진단')}
                              loading={downloadLoading}
                            />
                          </div>
                        </div>
                      )}

                      <Divider />

                      {partnerId || partnerLoginUser ? null : (
                        <div className="flex justify-content-between lg:flex-row md:flex-column flex-column lg:align-items-center w-full">
                          <div className="p-2 text-base flex align-items-center">
                            <div className="font-bold sm:text-base text-2xl mr-4 md:mr-5 lg:mr-8">
                              수리 여부
                            </div>
                            <RadioButton
                              inputId={'workY' + el.partnerId}
                              name="customerSearchBy"
                              value="I"
                              className="mr-2"
                              onChange={(e) => {
                                const updatedSelectedPartner =
                                  filteredSelectPartner.map((item) => {
                                    if (item.partnerId === el.partnerId) {
                                      return {
                                        ...item,
                                        claimStatusCode: e.value,
                                      };
                                    }
                                    return item;
                                  });
                                setSelectedPartner(updatedSelectedPartner);
                              }}
                              checked={el.claimStatusCode === 'I'}
                            />
                            <label htmlFor={'workY' + el.partnerId}>
                              수리 진행
                            </label>
                            <RadioButton
                              inputId={'workN' + el.partnerId}
                              name="customerSearchBy"
                              value="N"
                              className="ml-4 mr-2"
                              onChange={(e) => {
                                const updatedSelectedPartner =
                                  filteredSelectPartner.map((item) => {
                                    if (item.partnerId === el.partnerId) {
                                      return {
                                        ...item,
                                        claimStatusCode: e.value,
                                      };
                                    }
                                    return item;
                                  });
                                setSelectedPartner(updatedSelectedPartner);
                              }}
                              checked={el.claimStatusCode === 'N'}
                            />
                            <label htmlFor={'workN' + el.partnerId}>
                              미수리
                            </label>
                            <RadioButton
                              inputId={'workC' + el.partnerId}
                              name="customerSearchBy"
                              value="C"
                              className="ml-4 mr-2"
                              onChange={(e) => {
                                const updatedSelectedPartner =
                                  filteredSelectPartner.map((item) => {
                                    if (item.partnerId === el.partnerId) {
                                      return {
                                        ...item,
                                        claimStatusCode: e.value,
                                      };
                                    }
                                    return item;
                                  });
                                setSelectedPartner(updatedSelectedPartner);
                              }}
                              checked={el.claimStatusCode === 'C'}
                            />
                            <label htmlFor={'workC' + el.partnerId}>
                              당사면책
                            </label>
                          </div>
                          <div className="flex gap-4">
                            <DocsViewer
                              title="승인견적서"
                              imgFiles={approvedImg}
                              buttonName={'P_APPROVED' + el.partnerId}
                              setCurTitle={setCurTitle}
                              setShowModal={setShowModal}
                              curImg={curImg}
                              setCurImg={setCurImg}
                              deleteFile={deleteFile}
                              handleClick={handleClick}
                              upload={true}
                              setIsApproval={setIsApproval}
                            />
                            <DocsViewer
                              title="보험금청구동의서(빈 양식)"
                              imgFiles={insuranceTemplateImg}
                              buttonName={'P_INSURANCE_TEMPLATE' + el.partnerId}
                              setCurTitle={setCurTitle}
                              setShowModal={setShowModal}
                              curImg={curImg}
                              setCurImg={setCurImg}
                              deleteFile={deleteFile}
                              handleClick={handleClick}
                              upload={true}
                              setIsTemplate={setIsTemplate}
                            />
                          </div>
                          <div className="flex justify-content-end flex-wrap gap-2">
                            <Button.Default
                              className="p-button-outlined mr-1"
                              icon="pi pi-check-circle"
                              label="서류반영"
                              onClick={() => {
                                if (
                                  !insuranceTemplateImg?.length ||
                                  !approvedImg?.length
                                ) {
                                  window.cerp.toast.warn(
                                    '승인견적서, 보험금청구동의서 모두 업로드해주세요.'
                                  );
                                } else if (isComplete || isZ06) {
                                  onToggle('Z17', true);
                                } else {
                                  onToggle('Z07', false);
                                }
                              }}
                            />
                            <Button.Default
                              label="수리승인 안내"
                              icon="pi pi-comment"
                              className="p-button-outlined"
                              onClick={() => {
                                if (
                                  !insuranceTemplateImg?.length ||
                                  !approvedImg?.length
                                ) {
                                  window.cerp.toast.warn(
                                    '승인견적서, 보험금청구동의서 모두 업로드해주세요.'
                                  );
                                } else {
                                  setShowSmsModal(true);
                                  setShowSmsType('PARTNER_UPLOAD_URL');
                                  setSmsData({
                                    ...smsData,
                                    partnerInfo: el,
                                    destAddr: el.cellphone,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={`flex align-items-center ${
                          partnerLoginUser ? 'gap-4' : 'justify-content-between'
                        } mt-2 mb-2`}
                      >
                        <div className="flex align-items-center p-2 text-base">
                          <div className="font-bold sm:text-base text-2xl mr-4 md:mr-5 lg:mr-8">
                            <label htmlFor="">지급금액</label>
                          </div>
                          <div className="">
                            <InputText
                              value={
                                el?.paymentAmt
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  ?.toString() || ''
                              }
                              onChange={(e) => {
                                const updatedSelectedPartner =
                                  filteredSelectPartner.map((item) => {
                                    if (item.partnerId === el.partnerId) {
                                      return {
                                        ...item,
                                        paymentAmt: e.target.value.replaceAll(
                                          ',',
                                          ''
                                        ),
                                      };
                                    }
                                    return item;
                                  });
                                setSelectedPartner(updatedSelectedPartner);
                              }}
                              placeholder="지급금액 입력"
                              readOnly={partnerLoginUser}
                            />
                          </div>
                        </div>
                        {partnerLoginUser &&
                          !!approvedImg.length &&
                          !!insuranceTemplateImg.length && (
                            <div className="flex align-items-center gap-2 mt-1">
                              <DocsViewer
                                disabled={true}
                                title="승인견적서"
                                imgFiles={approvedImg}
                                buttonName={'P_APPROVED' + el.partnerId}
                                setCurTitle={setCurTitle}
                                setShowModal={setShowModal}
                                curImg={curImg}
                                setCurImg={setCurImg}
                                deleteFile={deleteFile}
                                handleClick={handleClick}
                                upload={true}
                                setIsApproval={setIsApproval}
                              />
                              <DocsViewer
                                disabled={true}
                                title="보험금청구동의서(빈 양식)"
                                imgFiles={insuranceTemplateImg}
                                buttonName={
                                  'P_INSURANCE_TEMPLATE' + el.partnerId
                                }
                                setCurTitle={setCurTitle}
                                setShowModal={setShowModal}
                                curImg={curImg}
                                setCurImg={setCurImg}
                                deleteFile={deleteFile}
                                handleClick={handleClick}
                                upload={true}
                                setIsTemplate={setIsTemplate}
                              />
                            </div>
                          )}
                      </div>

                      <div className="flex align-items-center justify-content-between">
                        <Calendar
                          value={el?.dueDate || null}
                          onChange={(e) => {
                            const updatedSelectedPartner =
                              filteredSelectPartner.map((item) => {
                                if (item.partnerId === el.partnerId) {
                                  return {
                                    ...item,
                                    dueDate: e.value,
                                  };
                                }
                                return item;
                              });
                            setSelectedPartner(updatedSelectedPartner);
                          }}
                          dateFormat="yy-mm-dd"
                          showButtonBar
                          showIcon
                          mask="9999-99-99"
                          placeholder="수리완료일자 선택"
                          id={el?.partenrId}
                          name="dueDate"
                          className="pl-2"
                        />
                        {!partnerUser && !partnerLoginUser ? (
                          <>
                            <div className="col px-2">
                              <div className="flex justify-content-end align-items-center">
                                <Button.Default
                                  label="미비서류 앱푸시"
                                  icon="pi pi-mobile"
                                  className="p-button-outlined mr-1"
                                  onClick={() => {
                                    setShowPushModal(true);
                                    setShowPushType('REPAIR_DOCS');
                                  }}
                                />
                              </div>
                            </div>
                            <div className="pr-2">
                              <ToggleButton
                                checked={
                                  checked?.find((el) => el.status === 'Z08')
                                    ?.value
                                }
                                onChange={(e) => onToggle('Z08', e.value)}
                                onLabel="완료"
                                offLabel="미확인"
                                onIcon="pi pi-flag-fill"
                                offIcon="pi pi-flag"
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="field grid align-items-center p-2 mb-0">
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="정비사진"
                            imgFiles={maintenanceImg}
                            buttonName={'P_MAINTENANCE' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_MAINTENANCE}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '정비사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="부품명세서(수리)"
                            imgFiles={completeImg}
                            buttonName={'P_COMPLETED' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_COMPLETED}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '부품명세서(수리)',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="보험금청구동의서"
                            imgFiles={insuranceImg}
                            buttonName={'P_INSURANCE' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_INSURANCE}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '보험금청구동의서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="사업자등록증"
                            imgFiles={registerImg}
                            buttonName={'P_REGISTRATION' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            // deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_REGISTRATION}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '사업자등록증',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="사업자통장사본"
                            imgFiles={bankImg}
                            buttonName={'P_BANKBOOK' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            // deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_BANKBOOK}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '사업자통장사본',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="기타"
                            imgFiles={etcImg}
                            buttonName={'P_ETC' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_ETC}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '기타',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                      </div>
                      {(!dealerUser || !partnerUser) && aId ? null : (
                        <div className="col px-2">
                          <div className="flex justify-content-end align-items-center h-3rem mt-2">
                            <Button.Default
                              className="p-button-outlined"
                              icon="pi pi-download"
                              label="다운로드"
                              onClick={() => saveFilesAsZip(el, '수리')}
                              loading={downloadLoading}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div>입고점을 선택해주세요.</div>
            )}
          </div>
        </BlockUI>
      </Panel>

      {showModal && curTitle && (
        <ImagesPopup
          title={curTitle}
          onHide={() => setShowModal(!showModal)}
          setFileItems={setFileItems}
          copyFile={copyFile}
          loading={loading}
          setLoading={setLoading}
          partnerId={curPartnerId}
          selectedPartner={selectedPartner}
        />
      )}
    </>
  );
};

export default PartnerDocument;

const ImagesPopup = ({
  onHide,
  title,
  partnerId,
  setFileItems,
  loading,
  setLoading,
  selectedPartner,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 20 * 1024 * 1024,
    accept: {
      'image/*': [], // 이미지 파일
      'video/*': [], // 비디오 파일
      'audio/*': [], // 오디오 파일
      'application/pdf': [], // PDF 파일
      'application/msword': [], // Word 파일 (.doc)
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [], // Word 파일 (.docx)
      'application/vnd.ms-excel': [], // Excel 파일 (.xls)
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // Excel 파일 (.xlsx)
      'application/vnd.ms-powerpoint': [], // PowerPoint 파일 (.ppt)
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        [], // PowerPoint 파일 (.pptx)
      'application/vnd.hancom.hwp': ['.hwp'], // 한글 파일 (.hwp)
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      setLoading(true);
      const options = {
        // maxSizeMB: 0.5,
        // maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      if (!!fileRejections.length) {
        console.log(fileRejections);
        setLoading(false);
        return window.cerp.toast.warn(
          '파일용량 20MB 이상 업로드 불가',
          `${fileRejections.length} 장`
        );
      }
      // console.log(acceptedFiles);
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      const compressedImages = await compressImages(acceptedFiles, options);

      let fileSize = 0;
      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      compressedImages.map((el) => {
        return (fileSize = Number(el.size) + fileSize);
      });

      if (fileSize / 1024 / 1024 > 50) {
        return window.cerp.toast.warn('50MB 이상', '한번에 업로드 불가');
      }

      const code = DOC_TYPE.find((el) => el.label === title).value;
      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({
            documentCode: code,
            file: v,
            state: FileState.Inserted,
          });
          return res;
        },
        []
      );
      const { fileData, files } = CommonUtil.File.convertForServerClaimPartner(
        partCodeAttachedFiles
      );

      const partner = selectedPartner.find((el) => el.partnerId === partnerId);
      if (partner?.partnerDocuments) {
        partCodeAttachedFiles.map((el) => partner?.partnerDocuments.push(el));
      } else {
        setLoading(false);
        onHide();
        return window.cerp.toast.warn('저장을 먼저 해주세요.');
      }

      setFileItems((ps) => [...ps, ...files]);
      setLoading(false);
      onHide();
      window.cerp.toast.info(
        '업로드 성공',
        '저장 버튼 클릭하여 등록해 주세요.'
      );
    },
  });

  return (
    <>
      <Modal.Default
        header={`${title} 등록`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            <div className="col">
              <div
                {...getRootProps({
                  className:
                    'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                  style: { wordBreak: 'keep-all' },
                })}
              >
                <input {...getInputProps()} />
                <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                  <i
                    className="pi pi-arrow-circle-down"
                    style={{ fontSize: '1.5rem' }}
                  />
                  사진을 끌어다 놓거나 클릭하여 등록해주세요.
                </div>
              </div>
            </div>
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};

const DOC_TYPE = [
  {
    value: 'P_CHECK',
    label: '점검사진',
  },
  {
    value: 'P_FRONT',
    label: '입고 차량전면사진',
  },
  {
    value: 'P_DASHBOARD',
    label: '입고 계기판사진',
  },
  {
    value: 'P_OPINION',
    label: '소견서',
  },
  {
    value: 'P_LIST',
    label: '견적서',
  },
  {
    value: 'P_PART',
    label: '부품명세서',
  },
  {
    value: 'P_MAINTENANCE',
    label: '정비사진',
  },
  {
    value: 'P_REGISTRATION',
    label: '사업자등록증',
  },
  {
    value: 'P_BANKBOOK',
    label: '사업자통장사본',
  },
  {
    value: 'P_INSURANCE',
    label: '보험금청구동의서',
  },
  {
    value: 'P_INSURANCE_TEMPLATE',
    label: '보험금청구동의서(빈 양식)',
  },
  {
    value: 'P_COMPLETED',
    label: '부품명세서(수리)',
  },
  {
    value: 'P_ETC',
    label: '기타',
  },
  {
    value: 'P_APPROVED',
    label: '승인견적서',
  },
];

const REQUIRED_DOCUMENT_CODES = [
  'P_FRONT',
  'P_DASHBOARD',
  'P_CHECK',
  'P_OPINION',
  'P_LIST',
];
