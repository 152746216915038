import * as Button from './../../components/Atoms/Button';

import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from '../../components/Atoms/Titled';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import QNAEditor from '../../components/Editor/QNAEditor';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import { Tag } from 'primereact/tag';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import { columnDateTime } from '../../utils/dataTableUtil';
import { myInfoSelector } from '../../recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';

const DEFAULT_SEARCH_CONDITIONS = {
  searchText: '',
  // postType: 'A',
};
const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const qnaService = ServiceProvider.qna;

const QNAList = () => {
  const history = useHistory();
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const { service } = useParams();

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [currentPostId, setCurrentPostId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  const [password, setPassword] = useState('');
  const [showPwPopup, setShowPwPopup] = useState({});

  async function getList(conditions, page = 0, size = 10) {
    const params = {
      ...conditions,
      page: page + 1,
      size: size,
    };
    setLoading(true);

    try {
      const {
        data: { list, total },
      } = await qnaService.getList(params);

      setData(list);
      setTotal(total);
    } catch (error) {
      window.cerp.dialog.error(
        'QNA 게시글 조회 실패',
        `[${error?.code}]${error?.message}`
      );
    }
    setLoading(false);
  }

  async function initList() {
    setSearchConditions({ ...DEFAULT_SEARCH_CONDITIONS });
    setTotal(0);
    setData([]);
    setLazyParams({ ...DEFAULT_LAZY_PARAMS });
  }

  function onClickTitle(userInfo, seq) {
    // 권한 있는 사람이면 비밀번호 입력 없이 게시글 페이지로 이동
    // 권한 없는 사람이면 비밀번호 인증 받은 후에 비밀번호가 같을 때만 페이지로 이동

    const { roleCode } = userInfo;

    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'CERP_ADM' ||
        r.value === 'A_MST' ||
        r.value === 'A_ADM' ||
        r.value === 'A_OFF'
      );
    });

    if (_.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0) {
      history.push(`/${service}/qna/${seq}`);
    } else {
      // 비밀번호 입력 다이얼로그 띄우기
      setShowPwPopup((ps) => ({ ...ps, [`${seq}`]: true }));
    }
  }

  async function checkVerify(id, password) {
    try {
      const { data } = await qnaService.verify({
        seq: id,
        password: password,
      });

      if (data) return history.push(`/${service}/qna/${id}`);
    } catch (error) {
      window.cerp.dialog.error(
        '게시글 비밀번호 확인 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  useEffect(() => {
    (async () => {
      await getList(searchConditions);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() => getList(searchConditions)}
                  >
                    <div className="field col-12">
                      {/* 제목 + 내용 검색 창 만들기 */}
                      <label htmlFor="searchText" className="font-bold">
                        제목 + 내용
                      </label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="제목 또는 내용 검색"
                        onChange={(e) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            searchText: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </SearchMenu>

                  <div className="card flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                    <div className="flex gap-1">
                      <Button.Default
                        label="QNA 신규 등록"
                        icon="pi pi-check-square"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => setCurrentPostId(0)}
                      />
                    </div>
                  </div>
                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      loading={loading}
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={total}
                      responsiveLayout="scroll"
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      resizableColumns
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        header="순번"
                        // field="seq"
                        bodyClassName="text-center"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        header="상태"
                        bodyClassName="text-center"
                        body={({ isAnswer }) => (
                          <Tag
                            value={
                              isAnswer === YN.YES ? '답변완료' : '접수완료'
                            }
                            className={classNames('text-sm', {
                              'bg-green-400': isAnswer === YN.YES,
                              'bg-orange-400': isAnswer !== YN.YES,
                            })}
                          />
                        )}
                      />
                      <Column
                        header="제목"
                        field="title"
                        bodyClassName="text-center"
                        style={{
                          minWidth: '15rem',
                          maxWidht: '15rem',
                        }}
                        body={({ seq, title }) => (
                          <div className="flex align-items-center gap-2">
                            <i className="pi pi-lock text-primary" />
                            <span
                              className="text-primary"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                onClickTitle(myInfoLoadable.contents, seq)
                              }
                            >
                              {title}
                            </span>

                            {/* 게시글 비밀번호 팝업 */}
                            <Dialog
                              visible={showPwPopup[seq]}
                              header={
                                <div>
                                  <i className="pi pi-unlock mr-2 text-xl font-bold" />
                                  <span>비밀번호 확인</span>
                                </div>
                              }
                              onHide={() =>
                                setShowPwPopup((ps) => ({
                                  ...ps,
                                  [`${seq}`]: false,
                                }))
                              }
                            >
                              <div className="flex flex-column justify-content-center gap-3">
                                <Password
                                  id="post-password"
                                  placeholder="게시글 비밀번호"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  feedback={false}
                                  autoFocus
                                />
                                <div className="button_wrapper flex justify-content-center gap-1">
                                  <Button.Default
                                    label="뒤로 가기"
                                    className="p-button-outlined"
                                    onClick={(e) =>
                                      setShowPwPopup((ps) => ({
                                        ...ps,
                                        [`${seq}`]: false,
                                      }))
                                    }
                                  />
                                  <Button.Default
                                    label="확인"
                                    onClick={(e) => checkVerify(seq, password)}
                                  />
                                </div>
                              </div>
                            </Dialog>
                          </div>
                        )}
                      />
                      <Column
                        header="작성자"
                        field="regName"
                        bodyClassName="text-center"
                        body={({ regName }) => <span>{regName}</span>}
                      />
                      <Column
                        header="게시일"
                        field="regDate"
                        bodyClassName="text-center"
                        body={({ regDate }) => (
                          <span>{columnDateTime(regDate)}</span>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentPostId !== null && (
                    <Dialog
                      visible
                      header="QNA 신규 등록"
                      style={{
                        width: '80vw',
                        height: '80vh',
                      }}
                      onHide={async () => {
                        setCurrentPostId(null);
                        await getList(searchConditions);
                      }}
                    >
                      <QNAEditor
                        postId={currentPostId}
                        onHide={async () => {
                          setCurrentPostId(null);
                          await getList(searchConditions);
                        }}
                      />
                    </Dialog>
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

export default QNAList;
