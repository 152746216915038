import React, { useEffect, useState } from 'react';
import * as Button from '../../Atoms/Button';
import FileState from '../../../enums/FileState';
import { PAYMENT_METHOD, YN } from '../../../constants/Constants';
import { CommonUtil } from '../../../utils/commonUtil';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../../services';
import { Tag } from 'primereact/tag';
import _ from 'lodash';
import dayjs from 'dayjs';
import { compressImages } from '../../../utils/compressUtil';
import { useDropzone } from 'react-dropzone';
import * as Modal from './../../../components/Atoms/Modal';
import { BlockUI } from 'primereact/blockui';
import { Image } from 'primereact/image';
import { Badge } from 'primereact/badge';
import filesize from 'filesize';
import { Dropdown } from 'primereact/dropdown';
import { PerfomancePayment } from '../../../services/PerformanceCheckService.js';

const performanceCheckService = ServiceProvider.performanceCheck;

const PaymentDialog = ({
  visible,
  onHide,
  dataId,
  getData,
  searchConditions,
  isLoading,
  lazyParams,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [curTitle, setCurTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileItems, setFileItems] = useState([]);
  const [refundReason, setRefundReason] = useState(null);
  const [data, setData] = useState(null);

  const getDetailData = async (id) => {
    setLoading(true);
    try {
      const data = await PerfomancePayment.getDetail(id);
      if (data) {
        setData(data.paymentData);
        setFileItems(data.paymentDoc);
        setRefundReason(data.paymentData.insurancePriceRefundedReason);
      }
    } catch (error) {
      const { response } = error;
      window.cerp.dialog.error(
        '결제정보 조회 실패',
        response?.data?.error?.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataId) {
      getDetailData(dataId);
    }
  }, [dataId]);

  const updatePayment = async (isRefund = false, type = '') => {
    setLoading(true);
    const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    let fileData = [];
    let files = [];

    if (type === '보험료 반환 취소') {
      if (fileItems?.length) {
        fileItems.map((el) =>
          fileData.push({
            imgId: el.imgId,
            documentCode: 'PAY',
            state: FileState.Deleted,
          })
        );
      }
    }
    if (type === '보험료 반환') {
      if (fileItems?.length) {
        fileItems.map((el) => files.push(el.file));
        fileItems.map((el) =>
          fileData.push({
            documentCode: el.documentCode,
            state: FileState.Inserted,
          })
        );
      }
    }

    switch (type) {
      case '일괄':
        data.checkPricePaidYn = 'Y';
        data.checkPricePaidAt = current;
        data.insurancePricePaidYn = 'Y';
        data.insurancePricePaidAt = current;
        data.fileData = fileData;
        data.msgSendYn = data.msgSendYn ?? 'N';
        break;
      case '점검료':
        data.checkPricePaidYn = 'Y';
        data.checkPricePaidAt = current;
        data.fileData = fileData;
        data.msgSendYn = data.msgSendYn ?? 'N';
        break;
      case '보험료':
        data.insurancePricePaidYn = 'Y';
        data.insurancePricePaidAt = current;
        data.fileData = fileData;
        data.msgSendYn = data.msgSendYn ?? 'N';
        break;
      case '보험료 반환':
        data.insurancePriceVAT = 0;
        data.insurancePrice = 0;
        data.insurancePriceRefundedAt = current;
        data.fileData = fileData;
        data.insurancePriceRefundedReason = refundReason;
        data.msgSendYn = data.msgSendYn ?? 'N';
        break;
      case '보험료 반환 취소':
        data.insurancePricePaidYn = 'N';
        data.insurancePricePaidAt = null;
        data.insurancePriceRefundedAt = null;
        data.fileData = fileData;
        data.insurancePriceRefundedReason = null;
        data.msgSendYn = data.msgSendYn ?? 'N';
        break;
      case '점검료 반환':
        data.checkPricePaidYn = 'N';
        data.checkPricePaidAt = null;
        data.fileData = fileData;
        data.msgSendYn = data.msgSendYn ?? 'N';
        break;
      default:
        break;
    }

    const updateData = {
      ...data,
    };
    // console.log(updateData, files);
    // return;
    try {
      const res = await performanceCheckService.updatePayment(
        updateData,
        files
      );
      if (isRefund) {
        window.cerp.toast.success(
          `${type} 완료`,
          `${data?.statementNumber}번 성능점검 ${type} 처리가 완료되었습니다.`
        );
      } else {
        window.cerp.toast.success(
          `수납 처리 완료`,
          `${data?.statementNumber}번 성능점검 수납 처리가 완료되었습니다.`
        );
      }
      if (res) {
        setData(res.data.paymentData);
        setFileItems(res.data.paymentDoc);
        getData(searchConditions, lazyParams.page, lazyParams.rows);
      }
    } catch (error) {
      if (isRefund) {
        window.cerp.dialog.error(
          `${type} 처리 실패`,
          error?.code
            ? `[${error?.code}] ${error?.message}`
            : '[서버 에러] 시스템 관리자에게 문의하세요.'
        );
      } else {
        window.cerp.dialog.error(
          '수납 처리 실패',
          error?.code
            ? `[${error?.code}] ${error?.message}`
            : '[서버 에러] 시스템 관리자에게 문의하세요.'
        );
      }
    } finally {
      setShowModal(false);
      setCurTitle('');
      setLoading(false);
    }
  };

  const totalCheckPrice =
    data?.checkPrice + data?.checkPriceVat - data?.discountedCheckPrice;
  const totalInsurance = data?.insurancePrice + data?.insurancePriceVat;
  const unPaidCheck = data?.checkPricePaidYn === 'N' ? totalCheckPrice : 0;
  const unPaidInsurance =
    data?.insurancePriceRefundedAt === null &&
    data?.insurancePricePaidAt === null
      ? totalInsurance
      : 0;

  const handleInsurancePayment = () => {
    if (
      data?.insurancePricePaidYn === YN.NO &&
      data?.insurancePriceRefundedAt !== null
    ) {
      updatePayment(true, '보험료 반환 취소');
    } else {
      setCurTitle('보험료 반환서류 등록');
      setShowModal(true);
      setRefundReason(null);
    }
  };

  return (
    <Dialog
      modal
      blockScroll
      header="수납 관리"
      breakpoints={{ '960px': '90vw' }}
      visible={visible}
      style={{ width: '76vw' }}
      onHide={onHide}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button.Default
            type="button"
            label="닫기"
            className="p-button-outlined"
            onClick={onHide}
          />
        </div>
      )}
    >
      <BlockUI blocked={isLoading || loading}>
        <div className="grid">
          <div className="col-12 my-2 py-0">
            <Panel header="1. 기본 정보">
              <table
                className="w-full h-full border-1 border-gray-300 border-round-sm"
                cellPadding="0"
                cellSpacing="0"
              >
                <thead />
                <tbody>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      점검일
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {dayjs(data?.checkDate).format('YYYY년 MM월 DD일')}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      매매상사
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {`${data?.customerCompanyName} ${
                        data?.customerCompanyContact &&
                        ` [${CommonUtil.Formatter.phone(
                          data?.customerCompanyContact
                        )}]`
                      }`}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      고객
                    </th>
                    <td className="px-2 border-gray-300">
                      {`${data?.customerName} ${
                        data?.customerContact &&
                        `[${CommonUtil.Formatter.phone(data?.customerContact)}]`
                      }`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>
          <div className="col-6 my-2 py-0">
            <Panel header="2. 점검료 정보">
              <table
                className="w-full h-full border-1 border-gray-300 border-round-sm"
                cellPadding="0"
                cellSpacing="0"
              >
                <thead />
                <tbody>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      점검료 (VAT 포함)
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {CommonUtil.Unit.format(
                        data?.checkPrice + data?.checkPriceVat
                      )}{' '}
                      원
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      할인 금액
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {CommonUtil.Unit.format(data?.discountedCheckPrice)} 원
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      할인 사유
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {data?.discountReason ? data?.discountReason : '-'}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      결제여부
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      <Tag
                        value={
                          data?.checkPricePaidYn === YN.YES
                            ? '결제완료'
                            : '결제대기'
                        }
                        className={`text-sm my-1 ${
                          data?.checkPricePaidYn === 'Y'
                            ? 'bg-green-400'
                            : 'bg-orange-400'
                        }`}
                      />
                      <span className="ml-2">
                        {data?.checkPricePaidAt !== null
                          ? data?.checkPricePaidAt
                              .replace('T', ' ')
                              .slice(0, -3)
                          : '-'}
                      </span>
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      결제수단
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {_.get(
                        _.find(PAYMENT_METHOD, {
                          value: data?.checkPricePaidBy,
                        }),
                        'label'
                      )}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300  bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      기결제금액 / 미결제금액
                    </th>
                    <td className="px-2 border-gray-300 ">
                      {CommonUtil.Unit.format(
                        data?.checkPricePaidYn === 'Y'
                          ? data?.checkPrice + data?.checkPriceVat
                          : 0
                      )}{' '}
                      원 /{' '}
                      {CommonUtil.Unit.format(
                        data?.checkPricePaidYn === 'N'
                          ? data?.checkPrice + data?.checkPriceVat
                          : 0
                      )}{' '}
                      원
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>
          <div className="col-6 my-2 py-0">
            <Panel header="3. 보험료 정보">
              <table
                className="w-full h-full border-1 border-gray-300 border-round-sm"
                cellPadding="0"
                cellSpacing="0"
              >
                <thead />
                <tbody>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      보험료 (VAT 포함)
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {CommonUtil.Unit.format(
                        data?.insurancePrice + data?.insurancePriceVat
                      )}{' '}
                      원
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      결제여부
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      <Tag
                        value={
                          (data?.insurancePricePaidYn === YN.YES &&
                            data?.insurancePriceRefundedAt === null) ||
                          data?.assuranceType === '1'
                            ? '결제완료'
                            : data?.insurancePricePaidYn === YN.NO &&
                              data?.insurancePriceRefundedAt === null
                            ? '결제대기'
                            : '반환완료'
                        }
                        className={`text-sm my-1 ${
                          data?.insurancePricePaidYn === 'Y' ||
                          data?.assuranceType === '1'
                            ? 'bg-green-400'
                            : data?.insurancePriceRefundedAt === null
                            ? 'bg-orange-400'
                            : 'bg-red-400'
                        }`}
                      />
                      <span className="ml-2">
                        {data?.insurancePricePaidYn === YN.YES &&
                        data?.insurancePriceRefundedAt === null
                          ? data?.insurancePricePaidAt
                              ?.replace('T', ' ')
                              .slice(0, -3)
                          : data?.insurancePricePaidYn === YN.NO &&
                            data?.insurancePriceRefundedAt !== null
                          ? data?.insurancePriceRefundedAt
                              ?.replace('T', ' ')
                              .slice(0, -3)
                          : '-'}
                      </span>
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      결제수단
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {_.get(
                        _.find(PAYMENT_METHOD, {
                          value: data?.insurancePricePaidBy,
                        }),
                        'label'
                      )}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      기결제금액 / 미결제금액
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {CommonUtil.Unit.format(
                        data?.checkPricePaidYn === 'Y'
                          ? data?.insurancePrice + data?.insurancePriceVat
                          : 0
                      )}{' '}
                      원 /{' '}
                      {CommonUtil.Unit.format(
                        data?.checkPricePaidYn === 'N'
                          ? data?.insurancePrice + data?.insurancePriceVat
                          : 0
                      )}{' '}
                      원
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      보험료 반환 여부
                    </th>
                    <td
                      className={`px-2 border-gray-300 border-bottom-1 ${
                        !!data?.insurancePriceRefundedAt ? 'text-red-500' : ''
                      }`}
                    >
                      {!!data?.insurancePriceRefundedAt
                        ? '보험료 반환 처리 완료'
                        : data?.insurancePricePaidYn === 'Y'
                        ? '보험료 반환 가능'
                        : data?.assuranceType === '1' &&
                          data?.insurancePricePaidYn === 'N'
                        ? '해당사항 없음'
                        : '-'}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      반환 사유
                    </th>
                    <td className={`px-2 border-gray-300`}>
                      <div className="flex align-items-center">
                        {data?.insurancePriceRefundedReason ?? '-'}

                        {!data?.insurancePriceRefundedReason &&
                        !fileItems?.length ? null : !!data?.insurancePriceRefundedReason &&
                          !!fileItems?.length ? (
                          <div
                            className="ml-2 cursor-pointer flex align-items-center"
                            onClick={() => {
                              setShowModal(true);
                              setCurTitle('보험료 반환서류 확인');
                            }}
                          >
                            <span>/</span>
                            <i className="pi pi-image mr-1 ml-2" />
                            <span>반환서류</span>
                          </div>
                        ) : (
                          <div
                            className="ml-2 cursor-pointer flex align-items-center"
                            onClick={() => {
                              setShowModal(true);
                              setCurTitle('보험료 반환서류 추가');
                            }}
                          >
                            <span>/</span>
                            <i className="pi pi-cloud-upload mr-1 ml-2" />
                            <span>추가</span>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>
          <div className="col-12 my-2 py-0">
            <Panel header="4. 총 합계 정보">
              <table
                className="w-full h-full border-1 border-gray-300 border-round-sm"
                cellPadding="0"
                cellSpacing="0"
              >
                <thead />
                <tbody>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      총 합계 금액 (VAT 포함)
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {CommonUtil.Unit.format(totalCheckPrice + totalInsurance)}{' '}
                      원
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      미결제 잔액
                    </th>
                    <td className="px-2 border-gray-300">
                      {CommonUtil.Unit.format(unPaidCheck + unPaidInsurance)} 원
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>
          <div className="button_container flex flex-auto justify-content-center gap-2 mt-2">
            <Button.Default
              type="button"
              className="p-button-outlined"
              icon="pi pi-wallet"
              label="점검료 수납"
              disabled={data?.checkPricePaidYn === YN.YES}
              onClick={() => {
                updatePayment(false, '점검료');
              }}
            />
            <Button.Default
              type="button"
              className="p-button-outlined"
              icon="pi pi-wallet"
              label="보험료 수납"
              disabled={
                data?.insurancePricePaidYn === YN.YES ||
                data?.insurancePriceRefundedAt !== null ||
                data?.assuranceType === '1'
              }
              onClick={() => {
                updatePayment(false, '보험료');
              }}
            />
            <Button.Default
              type="button"
              icon="pi pi-wallet"
              label="일괄 수납"
              disabled={
                data?.checkPricePaidYn === 'Y' ||
                data?.insurancePricePaidYn === 'Y' ||
                data?.insurancePriceRefundedAt !== null ||
                data?.assuranceType === '1'
              }
              onClick={() => {
                updatePayment(false, '일괄');
              }}
            />
            <Button.Default
              className="p-button-outlined p-button-danger"
              icon="pi pi-undo"
              label="점검료 반환"
              disabled={
                data?.checkPricePaidYn === YN.NO || data?.assuranceType === '1'
              }
              onClick={() => {
                updatePayment(true, '점검료 반환');
              }}
            />
            <Button.Default
              className="p-button-outlined p-button-danger"
              icon="pi pi-undo"
              label={
                data?.insurancePricePaidYn === YN.NO &&
                data?.insurancePriceRefundedAt !== null
                  ? '보험료 반환 취소'
                  : '보험료 반환'
              }
              disabled={
                data?.assuranceType === '1' ||
                (data?.insurancePricePaidYn === 'N' &&
                  data?.insurancePriceRefundedAt === null)
              }
              onClick={() => handleInsurancePayment()}
            />
          </div>
        </div>

        {showModal && curTitle && (
          <ImagesPopup
            title={curTitle}
            onHide={() => setShowModal(!showModal)}
            setFileItems={setFileItems}
            loading={loading}
            setLoading={setLoading}
            fileItems={fileItems}
            updatePayment={updatePayment}
            refundReason={refundReason}
            setRefundReason={setRefundReason}
          />
        )}
      </BlockUI>
    </Dialog>
  );
};

export default PaymentDialog;

const ImagesPopup = ({
  onHide,
  fileItems,
  title,
  setFileItems,
  loading,
  setLoading,
  updatePayment,
  refundReason,
  setRefundReason,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 8 * 1024 * 1024,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      setLoading(true);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      if (!!fileRejections.length) {
        setLoading(false);
        return window.cerp.toast.warn('올바른 이미지 형식이 아닙니다.');
      }
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      const compressedImages = await compressImages(acceptedFiles, options);

      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({
            documentCode: 'PAY',
            file: v,
            state: 'I',
          });
          return res;
        },
        []
      );

      if (fileItems?.length) {
        setFileItems([...fileItems, ...partCodeAttachedFiles]);
      } else {
        setFileItems([...partCodeAttachedFiles]);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <Modal.Default header={`${title}`} onHide={onHide} widthClass="30rem">
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            {title?.includes('확인') || title?.includes('추가') ? null : (
              <div className="col">
                <Dropdown
                  placeholder="반환사유 선택"
                  className="w-full"
                  options={REASON}
                  onChange={(e) => setRefundReason(e.value)}
                  value={refundReason}
                />
              </div>
            )}
            {title?.includes('확인') ? null : (
              <div className="col">
                <div
                  {...getRootProps({
                    className:
                      'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                    style: { wordBreak: 'keep-all' },
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                    <i
                      className="pi pi-arrow-circle-down"
                      style={{ fontSize: '1.5rem' }}
                    />
                    사진을 끌어다 놓거나 클릭하여 등록해주세요.
                  </div>
                </div>
              </div>
            )}
          </div>
          {fileItems?.length > 0 && (
            <div className="col-12 ">
              <div className="flex flex-auto flex-column gap-2 px-2">
                {fileItems?.map((item, idx) => {
                  const { state = FileState.Loaded } = item;
                  if (state !== FileState.Deleted) {
                    return (
                      <div
                        key={`FILE_${idx}`}
                        className="grid border-1 border-300 border-round-sm mt-0"
                      >
                        <div className="col-9">
                          <div className="flex flex-auto align-items-center justify-content-start">
                            {_.has(item, 'imgId') ? (
                              <>
                                <Image
                                  src={`${process.env.REACT_APP_S3_BASE_URL}${item.filePath}`}
                                  alt={item.originalFileName}
                                  preview
                                  imageClassName="border-round border-1 border-200"
                                  imageStyle={{
                                    width: 44,
                                    height: 44,
                                  }}
                                />
                                <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                  <span>{item.originalFileName}</span>
                                  <Badge
                                    value={`${filesize(item.fileSize, {
                                      round: 1,
                                      standard: 'jedec',
                                    })}`}
                                    severity="info"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <Image
                                  src={item?.file?.preview}
                                  alt={item?.file?.name}
                                  preview
                                  imageClassName="border-round border-1 border-200"
                                  imageStyle={{
                                    width: 44,
                                    height: 44,
                                  }}
                                />
                                <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                  <span>{item?.file?.name}</span>
                                  <Badge
                                    value={`${filesize(item?.file?.size, {
                                      round: 1,
                                      standard: 'jedec',
                                    })}`}
                                    severity="info"
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {title?.includes('확인') ? null : (
                          <div className="col-3">
                            <div className="flex align-items-center justify-content-end pt-2">
                              <Button.Default
                                type="button"
                                icon="pi pi-trash"
                                className="p-button-danger p-button-outlined"
                                onClick={() => {
                                  const clonedFiles = [...fileItems];
                                  const clonedFile = clonedFiles[idx];

                                  if (_.has(clonedFile, 'imgId')) {
                                    clonedFiles[idx] = {
                                      documentCode: clonedFile.documentCode,
                                      state: FileState.Deleted,
                                      imgId: clonedFile.imgId,
                                    };
                                  } else {
                                    clonedFiles.splice(idx, 1);
                                  }

                                  setFileItems(clonedFiles);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          )}
          {title?.includes('확인') ? null : (
            <div className="mt-4 flex justify-content-between gap-8 px-2">
              <Button.Default
                type="button"
                label="취소"
                icon="pi pi-times"
                className="p-button-outlined"
                onClick={() => {
                  onHide();
                  setFileItems([]);
                  setRefundReason(null);
                }}
              />
              <Button.Default
                type="button"
                label="등록"
                icon="pi pi-check"
                onClick={() => {
                  !refundReason
                    ? window.cerp.toast.warn('반환 사유를 선택해 주세요.')
                    : updatePayment(true, '보험료 반환');
                }}
              />
            </div>
          )}
        </BlockUI>
      </Modal.Default>
    </>
  );
};

// 수출/말소, 매입취소, 당일취소, 당사자거래이전, 상사이전, 경매/경락, 리스
const REASON = [
  { label: '수출/말소', value: '수출/말소' },
  { label: '매입취소', value: '매입취소' },
  { label: '당일취소', value: '당일취소' },
  { label: '당사자거래이전', value: '당사자거래이전' },
  { label: '상사이전', value: '상사이전' },
  { label: '경매/경락', value: '경매/경락' },
  { label: '리스', value: '리스' },
  { label: '기타', value: '기타' },
];
