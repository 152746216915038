import imageCompression from 'browser-image-compression';

export const compressImages = async (images, options) => {
  const compressedImages = [];

  for (let image of images) {
    try {
      if (image.type.startsWith('image/')) {
        // 이미지 파일 => imageCompression 압축
        const compressedBlob = await imageCompression(image, options);
        const compressedFile = new File([compressedBlob], image.name, {
          type: image.type,
        });
        compressedImages.push(compressedFile);
      } else {
        // 이미지 파일 X => 원본을 그대로 Blob으로 추가
        const compressedFile = new File([image], image.name, {
          type: image.type,
        });
        compressedImages.push(compressedFile);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return compressedImages;
};
