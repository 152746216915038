import React, { useEffect } from 'react';
import * as Button from './../../../components/Atoms/Button';
import { CommonUtil } from '../../../utils/commonUtil';

const DocsViewer = ({
  title,
  imgFiles,
  buttonName,
  setCurImg,
  setCurTitle,
  setShowModal,
  curImg,
  deleteFile,
  userType,
  handleClick,
  upload = false,
  setIsApproval,
  setIsTemplate,
  disabled = false,
}) => {
  useEffect(() => {
    if (title === '승인견적서') {
      const filteredFiles = imgFiles?.filter((el) => el.state === 'D') || []; // 삭제 시도 예정인 이미지
      if (
        imgFiles?.length === 0 ||
        (imgFiles?.length === 0 && filteredFiles.length > 0)
      ) {
        setIsApproval(false);
      } else if (
        filteredFiles.length > 0 &&
        filteredFiles.length >= imgFiles.length
      ) {
        setIsApproval(false);
      } else {
        setIsApproval(true);
      }
    }
  }, [imgFiles]);

  useEffect(() => {
    if (title === '보험금청구동의서(빈 양식)') {
      const filteredFiles = imgFiles?.filter((el) => el.state === 'D') || []; // 삭제 시도 예정인 이미지
      if (
        imgFiles?.length === 0 ||
        (imgFiles?.length === 0 && filteredFiles.length > 0)
      ) {
        setIsTemplate(false);
      } else if (
        filteredFiles.length > 0 &&
        filteredFiles.length >= imgFiles.length
      ) {
        setIsTemplate(false);
      } else {
        setIsTemplate(true);
      }
    }
  }, [imgFiles]);

  return (
    <div className="lg:my-1 md:my-2 my-3">
      <div className="grid justify-content-between align-items-center">
        <div
          className="col font-bold sm:text-base text-2xl sm:h-5 h-3"
          id="title"
        >
          {title}
        </div>
        <div className="text-base">
          {imgFiles?.length > 0
            ? imgFiles?.filter((el) => el.state !== 'D').length + ' 장'
            : !upload
            ? '접수 대기중'
            : '업로드하기'}
        </div>
        <div>
          {imgFiles?.length > 0 && (
            <>
              <Button.Default
                label="보기"
                icon="pi pi-window-maximize"
                className="p-button-text"
                onClick={() => {
                  const imageDocs = imgFiles.filter((item) =>
                    /\.(jpg|jpeg|png|gif|webp)$/i.test(item.originalFileName)
                  );
                  sessionStorage.setItem('images', JSON.stringify(imageDocs));
                  CommonUtil.Window.popup(
                    '/compensation/accident/claim/viewer',
                    '',
                    window.innerWidth * 0.9,
                    window.innerHeight * 0.9
                  );
                }}
              />
              <Button.Default
                icon={`pi ${
                  curImg === buttonName ? 'pi-angle-up' : 'pi-angle-down'
                }`}
                label=""
                className="p-button-text"
                onClick={() => {
                  curImg === buttonName ? setCurImg('') : setCurImg(buttonName);
                }}
                name={buttonName}
                disabled={disabled}
              />
            </>
          )}
        </div>
        {!disabled ? (
          <div
            onClick={
              !userType
                ? (e) => {
                    setCurTitle(e.target.id);
                    setShowModal(true);
                  }
                : (e) => handleClick(e)
            }
            className="flex justify-content-center cursor-pointer"
          >
            <i
              style={{
                color: 'var(--primary-color)',
                width: '28px',
              }}
              className={`pi ${
                upload ? 'pi-cloud-upload' : 'pi-upload'
              } flex justify-content-center sm:text-base text-xl`}
              id={title}
            />
          </div>
        ) : null}
      </div>
      {curImg === buttonName && (
        <div className="col">
          <div className="cursor-pointer flex justify-content-start gap-3 flex-wrap ">
            {imgFiles.map((el, idx) => {
              if (el.state === 'D') return;
              if (el.state === 'I' && !el.file) return;
              // console.log('el', el);
              const fileName = el?.filePath
                ? el?.filePath?.split('/').pop()
                : el?.file?.name;

              const fileExtension = fileName.split('.').pop().toLowerCase();
              // console.log('fileExtension', fileExtension);
              const imageExtensions = ['jpg', 'jpeg', 'png', 'webp', 'gif'];
              const isImage = imageExtensions.includes(fileExtension);
              // console.log('isImage', el, isImage);
              return el.state === 'D' ? null : (
                <span
                  className="docs-img flex flex-column align-items-center relative"
                  onClick={(e) => (!!deleteFile ? deleteFile(e) : null)}
                  key={idx}
                  id={`${el?.imgId || el.file?.name}`}
                >
                  {isImage ? (
                    // 이미지 파일일 경우 썸네일, 아닐 경우 div 태그
                    <img
                      height="30"
                      alt={el?.imgId || el.file?.name}
                      src={
                        el?.filePath
                          ? process.env.REACT_APP_S3_BASE_URL + el.filePath
                          : el?.file?.preview
                      }
                    />
                  ) : (
                    <div
                      className="bg-gray-100 p-2 flex align-items-center gap-1"
                      style={{ borderRadius: '10px' }}
                    >
                      <i
                        className="pi pi-file"
                        style={{ fontSize: '2.5rem', color: '#2196F3' }}
                      ></i>
                      <p key={idx} className="text-sm">
                        {/* 서버에 올라간 사진인지 아닌지 여부 */}
                        {el?.originalFileName
                          ? el?.originalFileName
                          : el?.file.name}
                      </p>
                    </div>
                  )}
                  {deleteFile ? (
                    <i
                      style={{
                        color: '#d9362b',
                        top: '10px',
                        fontWeight: 'bold',
                      }}
                      className="pi pi-minus-circle absolute"
                      id={`${el?.imgId || el?.file?.name}`}
                    />
                  ) : null}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocsViewer;
