import React, { forwardRef } from 'react';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

export const GroupTitledInputText = forwardRef((props, ref) => {
  const {
    // id,
    title = '',
    value,
    onChange,
    // error = null,
    readOnly = false,
    suffix = null,
    className = '',
    onKeyDown,
    onFocus,
    classNameProps,
    placeholder,
    disabled,
    boldStyle = false,
  } = props;

  return (
    <div className="p-inputgroup h-full flex flex-column">
      <div className="flex w-full h-full">
        <span
          className={`${
            classNameProps ? 'input-border-primary' : 'p-inputgroup-addon'
          } h-full w-full font-medium text-center min-w-3 w-4 max-w-5`}
          style={{
            display: 'table',
          }}
        >
          <span
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              wordBreak: 'keep-all',
            }}
          >
            {title}
          </span>
        </span>
        <InputText
          placeholder={placeholder}
          ref={ref}
          className={classNames(
            className,
            readOnly && (className === '' ? 'bg-gray-100' : className),
            boldStyle && 'font-bold'
          )}
          disabled={disabled}
          readOnly={readOnly}
          value={suffix ? `${value} ${suffix}` : value}
          onChange={onChange && ((e) => onChange(e))}
          // suffix={suffix || ''}
          onKeyDown={onKeyDown && ((e) => onKeyDown(e))}
          onFocus={onFocus && ((e) => onFocus(e))}
        />
      </div>
    </div>
  );
});
