import React from 'react';
const images = JSON.parse(sessionStorage.getItem('insurance'));
const InsuranceHistoryImage = () => {
  return (
    <div>
      <img src={images} alt="" className="w-full" />
    </div>
  );
};

export default InsuranceHistoryImage;
