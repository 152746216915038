import * as AccountDialog from './../../components/Dialog/AccountDialog';

import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import React, { useEffect, useState } from 'react';
import { columnDateTime, columnUseYn } from '../../utils/dataTableUtil';

import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import SearchMenu from '../../components/Menu/SearchMenu';
import ServiceMenu from '../../components/Menu/ServiceMenu';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable } from 'recoil';

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  searchText: '',
  useYN: 'Y',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const associationService = ServiceProvider.association;

const AssociationList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const [codes, setCodes] = useState({});
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    associationService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );
  const [currentAssociationId, setCurrentAssociationId] = useState(null);

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // 어드민
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return r.value === 'CERP_ADM';
    });

    return _.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0;
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    // 전체 값을 선택한 게 있으면
    // 나머지 옵션에 disabled 처리를 해서 더이상 선택하지 못하도록 함
    // 전체 값 선택 안 했으면
    // disabled 리셋해서 codes 업데이트
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
        const [associations, associationId] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: _.get(myInfo, 'myAssociationId'),
          });

        setCodes((ps) => ({ ...ps, associations }));

        await getList({ ...searchConditions, associations: [associationId] });
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() => getList(searchConditions)}
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showClear
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'associations');
                          setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">담당자</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="담당자 이름, 연락처로 검색"
                        onChange={({ target: { value } }) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            searchText: value,
                          }));
                          setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">사용여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_all"
                            name="useYN"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                              setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                            }}
                            checked={searchConditions.useYN === ''}
                          />
                          <label htmlFor="radio_useYN_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_y"
                            name="useYN"
                            value="Y"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                              setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                            }}
                            checked={searchConditions.useYN === YN.YES}
                          />
                          <label htmlFor="radio_useYN_y">사용</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_n"
                            name="useYN"
                            value="N"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                              setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                            }}
                            checked={searchConditions.useYN === YN.NO}
                          />
                          <label htmlFor="radio_useYN_n">미사용</label>
                        </div>
                      </div>
                    </div>
                  </SearchMenu>
                  <ServiceMenu
                    title={'협회'}
                    onClickRegisterBtn={() => setCurrentAssociationId(0)}
                    excelData={{
                      type: 'association',
                      params: {
                        ...searchConditions,
                        page: lazyParams.page + 1,
                        size: lazyParams.rows,
                      },
                    }}
                    buttonVisible={checkRegistAuth(myInfoLoadable.contents)}
                  />

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      onRowDoubleClick={(event) => console.log(event)}
                      loading={loading}
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        header="순번"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="associationCode"
                        header={
                          <span>
                            협회
                            <br />
                            협회코드
                          </span>
                        }
                        body={({
                          associationId,
                          associationName,
                          associationCode,
                        }) => (
                          <>
                            <a
                              href="."
                              role="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentAssociationId(associationId);
                              }}
                            >
                              {associationName}
                            </a>
                            <br />[{associationCode}]
                          </>
                        )}
                      />
                      <Column
                        field="enterpriseCount"
                        header={
                          <div>
                            <span>소속점검업체수</span>
                            <br />
                            <span>소속점검장수</span>
                            <br />
                            <span>소속사용자수</span>
                          </div>
                        }
                        bodyClassName="text-right"
                        body={({ enterpriseCount, shopCount, userCount }) => (
                          <div>
                            <span>
                              {enterpriseCount
                                ? enterpriseCount + '개'
                                : '없음'}
                            </span>
                            <br />
                            <span>{shopCount ? shopCount + '개' : '없음'}</span>
                            <br />
                            <span>{userCount ? userCount + '명' : '없음'}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="mainPhone"
                        header={
                          <div>
                            <span>대표전화</span>
                            <br />
                            <span>팩스</span>
                          </div>
                        }
                        body={({ mainPhone, fax }) => (
                          <div>
                            <span>
                              {mainPhone &&
                                CommonUtil.Formatter.phone(mainPhone)}
                            </span>
                            <br />
                            <span>{fax && CommonUtil.Formatter.fax(fax)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="agentCode"
                        header={
                          <div>
                            <span>담당자명</span>
                            <br />
                            <span>담당자연락처</span>
                            <br />
                            <span>담당자이메일</span>
                          </div>
                        }
                        body={({ personInCharge, picContact, picEmail }) => (
                          <div>
                            <span>{personInCharge}</span>
                            <br />
                            <span>{picContact}</span>
                            <br />
                            <span>{picEmail}</span>
                          </div>
                        )}
                      />
                      {/*<Column field="officialSealPath" header="직인" />*/}
                      <Column
                        field="agentCode"
                        header={
                          <div>
                            <span>연계기관코드</span>
                            <br />
                            <span>관리사업자번호</span>
                            <br />
                            <span>성능점검구분번호</span>
                          </div>
                        }
                        body={({
                          agentCode,
                          entrpsId,
                          statementNumberPrefix,
                        }) => (
                          <div>
                            <span>{agentCode}</span>
                            <br />
                            <span>
                              {entrpsId && CommonUtil.Formatter.biz(entrpsId)}
                            </span>
                            <br />
                            <span>{statementNumberPrefix}</span>
                          </div>
                        )}
                      />
                      <Column field="note" header="비고" />
                      <Column
                        field="useYn"
                        header="사용여부"
                        body={({ useYn }) => columnUseYn(useYn)}
                        bodyClassName="text-center"
                      />
                      <Column
                        field="regTime"
                        header={
                          <div>
                            <span>등록사용자</span>
                            <br />
                            <span>등록일시</span>
                          </div>
                        }
                        body={({ regTime, regByUserName }) => (
                          <div>
                            <span>{regByUserName}</span>
                            <br />
                            <span>{columnDateTime(regTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="modTime"
                        header={
                          <div>
                            <span>수정사용자</span>
                            <br />
                            <span>수정일시</span>
                          </div>
                        }
                        body={({ modTime, modByUserName }) => (
                          <div>
                            <span>{modByUserName}</span>
                            <br />
                            <span>{columnDateTime(modTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="delTime"
                        header={
                          <div>
                            <span>삭제사용자</span>
                            <br />
                            <span>삭제일시</span>
                          </div>
                        }
                        body={({ delTime, delByUserName }) => (
                          <div>
                            <span>{columnDateTime(delTime)}</span>
                            <br />
                            <span>{delByUserName}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentAssociationId !== null && (
                    <AccountDialog.Association
                      visible
                      userInfo={myInfoLoadable.contents}
                      associationId={currentAssociationId}
                      onHide={async () => {
                        setCurrentAssociationId(null);
                        await getList(
                          searchConditions,
                          lazyParams.page,
                          lazyParams.rows
                        );
                      }}
                    />
                  )}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AssociationList, comparisonFn);
