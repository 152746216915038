import React from 'react';
import { CommonUtil } from '../../../utils/commonUtil';
import * as Button from '../../Atoms/Button';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import Claim from '../../../services/ClaimService';

const ImageBox = ({
  title,
  code,
  partner = false,
  partnerData = [],
  docs = [],
}) => {
  const countFilesByCode = (docsArray, code) => {
    if (docsArray) return docsArray.filter((doc) => doc.documentCode === code);
  };
  let filterDocs;
  if (partner && partnerData.length) {
    filterDocs = countFilesByCode(partnerData, code);
  } else {
    filterDocs = countFilesByCode(docs, code);
  }
  const isSingle = filterDocs.length <= 1;

  // 개별파일 다운로드
  const saveFile = async (file) => {
    if (!file) return window.cerp.toast.warn('서류가 없습니다.');
    const folderName = `${file.originalFileName}`;

    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);

    const imgBlob = await Claim.getBlobFromUrl(file.filePath);

    const fileName = file.originalFileName;
    imagesFolder.file(fileName, imgBlob, { binary: true });

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  return (
    <div className={isSingle ? 'imageBox half' : 'imageBox full'}>
      <p className="section_sub_title">{title}</p>
      <div className={!isSingle ? 'twice-grid' : ''}>
        {filterDocs.map((doc) => {
          const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(
            doc.originalFileName
          );
          return isImage ? (
            <div key={doc.imgId} className="image-container">
              <img
                onClick={() => {
                  const imageDocs = filterDocs.filter((item) =>
                    /\.(jpg|jpeg|png|gif|webp)$/i.test(item.originalFileName)
                  );
                  sessionStorage.setItem('images', JSON.stringify(imageDocs));
                  CommonUtil.Window.popup(
                    '/compensation/accident/claim/viewer',
                    '',
                    window.innerWidth * 0.9,
                    window.innerHeight * 0.9
                  );
                }}
                src={process.env.REACT_APP_S3_BASE_URL + doc.filePath}
                alt={doc.originalFileName}
                style={{ width: '100%' }}
              />
            </div>
          ) : (
            <div
              className="bg-gray-100 p-3 m-3 flex align-items-center justify-content-center"
              style={{ borderRadius: '10px' }}
              key={doc.imgId}
            >
              <div className="flex flex-wrap justify-content-center">
                <i
                  className="pi pi-file pb-4"
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '5rem',
                    color: '#2196F3',
                  }}
                ></i>
                <p className="w-full text-center">{doc.originalFileName}</p>
                <Button.Default
                  className="p-button-outlined border-none bg-transparent text-blue-600"
                  icon="pi pi-download"
                  style={{ fontWeight: 'bold' }}
                  label={`다운로드`}
                  onClick={() => saveFile(doc)}
                  // loading={downloadLoading}
                />
              </div>
            </div>
          );
        })}
        {/* 이미지 없을 경우 */}
        {filterDocs.length === 0 && (
          <div className="empty-container">
            <p>NO IMAGE</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageBox;
