import * as Button from './../../components/Atoms/Button';

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import BoardPostType from '../../enums/BoardPostType';
import { COUNT_PER_PAGE } from '../../constants/Constants';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from '../../components/Atoms/Titled';
import { Link } from 'react-router-dom';
import MultiEditor from '../../components/Editor/MultiEditor';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { columnDateTime } from '../../utils/dataTableUtil';
import { myInfoSelector } from '../../recoil/selectors';
import { touchUIState } from '../../recoil/atoms';

const DEFAULT_SEARCH_CONDITIONS = {
  searchText: '',
  postType: '',
};
const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const boardService = ServiceProvider.board;

const BoardList = () => {
  const touchUI = useRecoilValue(touchUIState);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const { pathname } = useLocation();
  const params = useParams();
  const history = useHistory();
  const { service } = params;

  const defaultSearchConditions = useMemo(() => {
    const { service } = params;
    const postType = _.get(_.find(BoardPostType, { service }), 'notice');

    return {
      ...DEFAULT_SEARCH_CONDITIONS,
      postType: postType,
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const [searchConditions, setSearchConditions] = useState({
    ...defaultSearchConditions,
  });
  const [currentPostId, setCurrentPostId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  async function getList(conditions, page = 0, size = 10) {
    const params = {
      ...conditions,
      page: page + 1,
      size: size,
    };
    setLoading(true);

    try {
      const {
        data: { list, total },
      } = await boardService.getList(params);

      setData(list);
      setTotal(total);
    } catch (error) {
      window.cerp.dialog.error(
        '공지사항 게시글 조회 실패',
        `[${error?.code}]${error?.message}`
      );
    }
    setLoading(false);
  }

  async function initList() {
    setSearchConditions({ ...defaultSearchConditions });
    setTotal(0);
    setData([]);
    setLazyParams({ ...DEFAULT_LAZY_PARAMS });
  }

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // 어드민, 협회
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'CERP_ADM' ||
        r.value === 'A_MST' ||
        r.value === 'A_ADM' ||
        r.value === 'A_OFF'
      );
    });

    return _.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0;
  }

  useEffect(() => {
    (async () => {
      await getList(defaultSearchConditions);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    setSearchConditions((ps) => ({ ...ps, ...defaultSearchConditions }));
  }, [defaultSearchConditions]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() => getList(searchConditions)}
                  >
                    {/* <div className="field col-12 sm:col-6 lg:col-4">
                      <label htmlFor="range">작성일자</label>
                      <RangeCalendar
                        startDate={searchConditions.startDate}
                        endDate={searchConditions.endDate}
                        onStartDateChanged={(date) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            startDate: date,
                          }));
                        }}
                        onEndDateChanged={(date) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            endDate: date,
                          }));
                        }}
                        touchUI={touchUI}
                        showNavigators={true}
                      />
                    </div> */}
                    <div className="col-12">
                      <h3 className="font-semibold">{`${
                        service === 'customer-app' ? '소비자 APP ' : ''
                      }공지사항`}</h3>
                    </div>
                    <div className="field col-12">
                      {/* 제목 + 내용 검색 창 만들기 */}
                      <label htmlFor="searchText" className="font-bold">
                        제목 + 내용
                      </label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="제목 또는 내용 검색"
                        onChange={(e) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            searchText: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </SearchMenu>
                  {checkRegistAuth(myInfoLoadable.contents) && (
                    <div className="card flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                      <div className="flex gap-1">
                        <Button.Default
                          label={`${
                            service === 'customer-app' ? '소비자 APP ' : ''
                          }공지사항 신규 등록`}
                          icon="pi pi-check-square"
                          style={{ marginLeft: 'auto' }}
                          onClick={() => {
                            console.log('button click!');
                            setCurrentPostId(0);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      loading={loading}
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={total}
                      responsiveLayout="scroll"
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      resizableColumns
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        header="순번"
                        // field="seq"
                        bodyClassName="text-center"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        header="제목"
                        field="title"
                        bodyClassName="text-center"
                        style={{
                          minWidth: '15rem',
                          maxWidht: '15rem',
                        }}
                        body={({ seq, title }) => (
                          <Link to={`/${service}/post/${seq}`}>{title}</Link>
                        )}
                      />
                      <Column
                        header="작성자"
                        field="regName"
                        bodyClassName="text-center"
                        body={({ regName }) => <span>{regName}</span>}
                      />
                      <Column
                        header="조회수"
                        field="count"
                        bodyClassName="text-center"
                        body={({ count }) => <span>{count}</span>}
                      />
                      <Column
                        header="게시일"
                        field="regDate"
                        bodyClassName="text-center"
                        body={({ regDate }) => (
                          <span>{columnDateTime(regDate)}</span>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentPostId !== null && (
                    <Dialog
                      visible
                      header={`${
                        service === BoardPostType.CustomerApp.service
                          ? '소비자 APP '
                          : ''
                      }공지사항`}
                      style={{
                        width: '80vw',
                        height: '80vh',
                      }}
                      onHide={async () => {
                        await getList(searchConditions);
                        setCurrentPostId(null);
                      }}
                    >
                      <MultiEditor
                        service={service}
                        postType={_.get(defaultSearchConditions, 'postType')}
                        postId={currentPostId}
                        onHide={async (seq) => {
                          if (seq) {
                            history.replace(`/${service}/post/${seq}`);
                          } else {
                            await getList(searchConditions);
                          }
                          setCurrentPostId(null);
                        }}
                      />
                    </Dialog>
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

export default BoardList;
